<script>
import { NodeUtils } from './util.js'
const isCondition = data => data.type === 'condition'
const notEmptyArray = arr => Array.isArray(arr) && arr.length > 0
const hasBranch = data => notEmptyArray(data.conditionNodes)
const stopPro = ev => ev.stopPropagation()

function createNormalCard(ctx, conf, h) {
  const classList = ['flow-path-card']
  const afterTrue = (isTrue, name) => (isTrue && classList.push(name), isTrue)
  const isStartNode = afterTrue(NodeUtils.isStartNode(conf), 'start-node')
  const isApprNode = afterTrue(NodeUtils.isApproverNode(conf), 'approver')
  const isCopyNode = afterTrue(NodeUtils.isCopyNode(conf), 'copy')
  let contentList = conf.content.split('&')
  let approvalStr = contentList[0]
  let copyStr = contentList[1]

  let contentTag = 
      <a-tooltip effect="light" style="width: calc( 100% - 12px )" placement="top">
        <div slot="title" >
          <pre class='text contentText'>
            { approvalStr }
            <br />
            { copyStr }
          </pre>
        </div>
        <div>{
          <div class='text contentText'>
            <div class="ellipsis1">{ approvalStr }</div>
            <div class="ellipsis1">{ copyStr }</div>
          </div>
        }</div>
      </a-tooltip>
  return (
    <section class={classList.join(' ')} onClick={isStartNode ? () => {} : this.eventLancher.bind(ctx, 'edit', conf, isStartNode)} >
      <header class='header'>
        <div class='title-box' style='height: 100%;width:190px;'>
          {isApprNode && (
            <i class='iconfont el-icon-s-check' style='font-size:12px;color:white;margin-right:4px;'></i>
          )}
          {isCopyNode && (
            <i class='el-icon-s-promotion' style='font-size:12px;color:white;margin-right:4px;'></i>
          )}
          <span class='title-text'>{conf.title}</span>
          {!isStartNode && (
            <input vModel_trim={conf.title} class='title-input' style='margin-top:3px;' onClick={stopPro} />
          )}
        </div>
        <div class='actions' style='margin-right:4px;'>
          <a-icon type="close" class='icon' onClick={this.eventLancher.bind(ctx, 'deleteNode', conf, ctx.data)} />
        </div>
      </header>
      <div class='body'>
        { contentTag }
        <div class='icon-wrapper right'>
          <a-icon type="right" class="icon right-arrow" />
        </div>
      </div>
    </section>
  )
}
// arg = ctx, data, h
const createFunc = (...arg) => createNormalCard.call(arg[0], ...arg)
const nodes = {
  start: createFunc,
  approval: createFunc,
  copy: createFunc,
  empty: _ => '',
  condition: function(ctx, conf, h) {
    let contentTag = 
      <a-tooltip effect="light" style="width: calc( 100% - 12px )" placement="top">
        <div slot="title" >
          <span class='text contentText' >{conf.content}</span>
        </div>
        <div>{
          <span class='text contentText' >{conf.content}</span>
        }</div>
      </a-tooltip>
    return (
      <section
        class={`flow-path-card condition ${conf.defaultCondition ? 'defaultClass' : ''}`}
        onClick={conf.defaultCondition ? () => {} : this.eventLancher.bind(ctx, 'edit', conf)}
      >
        <header class='header'>
          <div class='title-box'>
            <span class='title-text'>{conf.title}</span>
            {
              <input
                vModel_trim={conf.title}
                class='title-input'
                style='margin-top:1px;'
                onClick={stopPro}
              />
            }
          </div>
          <div class='actions'>
            {
              !conf.defaultCondition && <a-icon
              type="close"
              class='icon'
              onClick={this.eventLancher.bind(
                ctx,
                'deleteNode',
                conf,
                ctx.data
              )}
            />
            }
          </div>
        </header>
        <div class='body'>
          { contentTag }
        </div>
      </section>
    )
  }
}

function addNodeButton(ctx, data, h, isBranch = false) {
  // 只有非条件节点和条件分支树下面的那个按钮 才能添加新分支树
  // const couldAddBranch = !hasBranch(data) || isBranch
  const isEmpty = data.type === 'empty'
  if (isEmpty && !isBranch) {
    return ''
  }
  //   <div>
  //   <div class='condition-icon' onClick={ctx.eventLancher.bind(ctx, 'addCopyNode', data, isBranch)} >
  //     <i class='el-icon-s-promotion iconfont' style='vertical-align: middle;'></i>
  //   </div>
  //   抄送人
  // </div>
  return (
    <div class='add-node-btn-box flex  justify-center'>
      <div class='add-node-btn'>
        <a-popover placement='right' trigger='click' width='210'>
          <div slot='content' class='condition-box'>
            <div>
              <div class='condition-icon' onClick={ctx.eventLancher.bind(ctx, 'addApprovalNode', data, isBranch)} >
                <svg class="iconpark-icon iconfont"><use href="#audit-5nkcdan4"></use></svg>
              </div>
              审批人
            </div>

            <div>
              <div class='condition-icon' onClick={this.eventLancher.bind(ctx, 'appendBranch', data, isBranch)}>
                <svg class="iconpark-icon iconfont"><use href="#split-turn-down-left"></use></svg>
              </div>
              条件分支
            </div>
          </div>

          <button class='btn' type='button'>
            <a-icon type="plus" class="icon" />
          </button>
        </a-popover>
      </div>
    </div>
  )
}

function NodeFactory(ctx, data, h) {
  if (!data) return
  const showErrorTip = ctx.verifyMode && NodeUtils.checkNode(data) === false
  const res = []
  let branchNode = ''
  const selfNode = (
    <div class='node-wrap'>
      <div class={`node-wrap-box ${data.type} ${showErrorTip ? 'error' : ''}` }>
        <a-tooltip title={data.type === 'condition' ? '请完善条件节点' : '请完善审批节点'} placement='top' effect='dark'>
          <div class='error-tip' onClick={this.eventLancher.bind(ctx, 'edit', data)}>!!!</div>
        </a-tooltip>
        {nodes[data.type].call(ctx, ctx, data, h)}
        {addNodeButton.call(ctx, ctx, data, h)}
      </div>
    </div>
  )

  if (hasBranch(data)) {
    // 如果节点是数组 一定为条件分支 添加分支样式包裹
    // {data. children && NodeFactory.call(ctx, ctx, data. children, h)}
    branchNode = (
      <div class='branch-wrap'>
        <div class='branch-box-wrap'>
          <div class='branch-box  flex justify-center relative'>
            <button
              class='btn'
              onClick={this.eventLancher.bind(ctx, 'appendConditionNode', data)}
            >
              添加条件
            </button>
            {data.conditionNodes.map(d => NodeFactory.call(ctx, ctx, d, h))}
          </div>
        </div>
        {addNodeButton.call(ctx, ctx, data, h, true)}
      </div>
    )
  }

  if (isCondition(data)) {
    return (
      <div class='col-box'>
        <div class='center-line'></div>
        <div class='top-cover-line'></div>
        <div class='bottom-cover-line'></div>
        {selfNode}
        {branchNode}
        {NodeFactory.call(ctx, ctx, data.children, h)}
      </div>
    )
  }
  res.push(selfNode)
  branchNode && res.push(branchNode)
  data.children && res.push(NodeFactory.call(ctx, ctx, data.children, h))
  return res
}

function addEndNode(h) {
  return <section class='end-node'>流程结束</section>
}

export default {
  name: 'FlowCard',
  props: {
    data: { type: Object, required: true },
    // 点击发布时需要校验节点数据完整性 此时打开校验模式
    verifyMode: { type: Boolean, default: true }
  },
  watch: {

  },
  methods: {
    /**
     *事件触发器 统筹本组件所有事件并转发到父组件中
     * @param { Object } 包含event（事件名）和args（事件参数）两个参数
     */
    eventLancher(event, ...args) {
      // args.slice(0,-1) vue 会注入MouseEvent到最后一个参数 去除事件对象
      const param = { event, args: args.slice(0, -1) }
      // 开始节点不需要触发属性编辑的操作
      // if (param.args[param.args.length - 1]) return
      this.$emit('emits', param)
    }
  },
  render(h) {
    return (
      <div style='display: inline-flex; flex-direction: column; align-items: center;'>
        {this.data && NodeFactory.call(this, this, this.data, h)}
        {addEndNode(h)}
      </div>
    )
  }
}
</script>

<style lang="stylus" scoped>
@import 'index.styl';

</style>
<style lang="less" scoped>
.ellipsis1{
  .multi-ellipsis(1);
}
</style>
