var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c("k-form-build", {
        ref: "KFB",
        staticClass: "form",
        attrs: { value: _vm.jsonData },
        on: { change: _vm.change },
      }),
      _vm.showSelectUser
        ? _c(
            "a-form",
            {
              staticClass: "form",
              attrs: {
                labelLayout: "flex",
                layout: "horizontal",
                labelWidth: "100px",
              },
            },
            [
              _c(
                "a-form-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: {
                    label: "发起人",
                    "label-col": { style: "width:100px" },
                  },
                },
                [
                  _c("UserSelect", {
                    ref: "UserSelect",
                    attrs: { echoData: _vm.userList, basicSetting: "" },
                    on: { change: _vm.perModelConfirm },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }