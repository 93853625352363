<template>
  <section class="approver-pane" style="height: 100%" v-if="cacheData">
    <!-- 审批人 --> 
    <template>
    </template>
    <a-card v-for="(item, index) in cacheData.assigneeConfig.assigneeParticipants" :key="`assigneeParticipants${index}`">
      <div class="cardHeader" slot="title">
        <span>审批人选择</span>
        <svg class="iconpark-icon"  v-if="cacheData.assigneeConfig.assigneeParticipants.length > 1" @click="delItem(index, 'approval')"><use href="#delete"></use></svg>
        <!-- <a-button v-if="cacheData.assigneeConfig.assigneeParticipants.length > 1" type="link" @click="delItem(index, 'approval')">删除</a-button> -->
      </div>
      <div class="approvalItem">
        <!-- 审批人类型 -->
        <a-radio-group v-model="item.participantType" @change="participantTypeChange(...arguments, index, 'assigneeConfig')">
          <template v-for="(key, index1) in approvalList">
            <a-radio
              :value="key.value"
              :key="`assigneeParticipantsItem${index1}`"
              class="approver-selection"
              :disabled="cacheData.assigneeConfig.assigneeParticipants.length > 1 && key.value === 'initiatorselect'"
            >{{ key.label }}</a-radio>
          </template>
        </a-radio-group>
        <!-- 不同类型对应的内容 -->
        <!-- 部门负责人 -->
        <template v-if="item.participantType === 'depthead'">
          <div class="line"></div>
          <h3 class="yang">选择部门负责人</h3>
          <ReCodeSelect v-model="item.participants" :participantType="item.participantType" :options="deptheadList" />
        </template>
        <!-- 指定成员 -->
        <template v-if="item.participantType === 'assignperson'">
          <div class="line"></div>
          <h3 class="yang">添加成员</h3>
          <PerTreeSelect
            :perTreeValue="
              item.participants
              .map(PerTreeSelectItem => PerTreeSelectItem.participant)
            "
            placeholder="请选择人员"
            modeType="multiple"
            :replaceFields="{
              name: 'participantType',
              id: 'participants'
            }"
            @getUserInfo="
              PerTreeSelectFunc(...arguments,
              item.participants,
              item.participantType)"
          />
        </template>
        <!-- 发起人自选 -->
        <template v-if="
            item.participantType === 'initiatorselect' &&
            item.initiatorParticipants
          ">
          <div class="line"></div>
          <h3 class="yang">选择方式</h3>
          <a-radio-group v-model="item.initiatorSelectType">
            <a-radio v-for="(
                initiatorParticipantsItem, initiatorParticipantsIndex
              ) in initiatorSelectType" :value="initiatorParticipantsItem.value" :key="`initiatorParticipantsItem${initiatorParticipantsIndex}`">{{ initiatorParticipantsItem.label }}</a-radio>
          </a-radio-group>
          <div class="line"></div>
          <h3 class="yang">可选范围</h3>
          <a-radio-group v-model="item.initiatorParticipants.participantType" @change="item.initiatorParticipants.participants = []">
            <a-radio v-for="(
                initiatorParticipantsRangeItem, initiatorParticipantsRangeIndex
              ) in initiatorParticipantsRangeList" :value="initiatorParticipantsRangeItem.value" :key="`initiatorParticipantsRangeItem${initiatorParticipantsRangeIndex}`">{{ initiatorParticipantsRangeItem.label }}</a-radio>
          </a-radio-group>
          <template v-if="item.initiatorParticipants.participantType === 'assignperson'">
            <div class="line"></div>
            <h3 class="yang">添加成员</h3>
            <UserSelect :echoData="item.initiatorParticipants.participants" @change="
                perModelConfirm(
                  ...arguments,
                  item.initiatorParticipants.participants,
                  item.initiatorParticipants.participantType
                )
              " />
          </template>
          <template v-if="item.initiatorParticipants.participantType === 'role'">
            <div class="line"></div>
            <h3 class="yang">添加角色</h3>
            <ReCodeSelect v-model="item.initiatorParticipants.participants" :participantType="item.initiatorParticipants.participantType" :options="approvalRoleList" />
          </template>
        </template>
        <!-- 汇报上级 -->
        <template v-if="item.participantType === 'superiors'">
          <div class="line"></div>
          <h3 class="yang">选择汇报上级</h3>
          <ReCodeSelect v-model="item.participants" :participantType="item.participantType" :options="reportSuperList" />
        </template>
        <template v-if="item.participantType === 'role'">
          <div class="line"></div>
          <h3 class="yang">选择角色</h3>
          <ReCodeSelect v-model="item.participants" :participantType="item.participantType" :options="approvalRoleList" />
        </template>
      </div>
    </a-card>
    <template v-if="canaddApproval">
      <div class="approval-button">
        <a-button type="link" prefix="add-one" @click="addApproval('approval')">添加审批人</a-button>
      </div>
    </template>

    <!-- 条件 -->
    <!-- 审批方式 -->
    <template>
      <div class="approv-type">
        <div class="approv">审批设置</div>
        <div class="approv-body">
          <h3 class="appro">多人审批时采用的审批方式</h3>
          <a-radio-group v-model="cacheData.assigneeConfig.approvalType">
            <template v-for="(key, index1) in approvalTypeList">
              <a-radio :value="key.value" :key="`approvalTypeItem${index1}`" class="approver-selects">{{ key.label }}</a-radio>
            </template>
          </a-radio-group>
          <!-- 审批人为空 -->
          <h3 class="top-height">审批人为空时</h3>
          <a-radio-group v-model="cacheData.assigneeConfig.vacantSkip" @change="vacantSkipChange">
            <template v-for="(key, index1) in vacantSkipList">
              <a-radio :value="key.value" :key="`approvalTypeItem${index1}`" class="approver-selectss">{{ key.label }}</a-radio>
            </template>
            <template v-if="cacheData.assigneeConfig.vacantSkip === 'assignperson'">
              <h3 class="top-height">审批候选人</h3>
                <PerTreeSelect
                  :perTreeValue="
                    cacheData.assigneeConfig.vacantCandidateUser
                    .map(PerTreeSelectItem => PerTreeSelectItem.participant)
                  "
                  placeholder="请选择人员"
                  modeType="multiple"
                  :replaceFields="{
                    name: 'participantType',
                    id: 'participants'
                  }"
                  @getUserInfo="
                    PerTreeSelectFunc(...arguments,
                    cacheData.assigneeConfig.vacantCandidateUser,
                    cacheData.assigneeConfig.vacantSkip)"
                />
            </template>
          </a-radio-group>

          <!-- 审批人与发起人同一人时 -->
          <h3 class="top-height">审批人与发起人同一人时</h3>
          <a-radio-group v-model="cacheData.assigneeConfig.conflictType">
            <template v-for="(key, index1) in conflictTypeList">
              <a-radio :value="key.value" :key="`approvalTypeItem${index1}`" class="approver-selections">{{ key.label }}</a-radio>
            </template>
          </a-radio-group>
        </div>
      </div>
    </template>
    <!-- 条件结束 -->
    <!-- 抄送人开始 -->
    <template>
    </template>

    <a-card v-for="(item, index) in cacheData.ccConfig.ccParticipants" :key="`copyParticipants${index}`">
      <div class="cardHeader" slot="title">
        <span>抄送人选择</span>
        <svg class="iconpark-icon" @click="delItem(index, 'copy')"><use href="#delete"></use></svg>
      </div>
      <div class="approvalItem">
        <!-- 抄送人人类型 -->
        <a-radio-group v-model="item.participantType" @change="participantTypeChange(...arguments, index, 'ccConfig')">
          <template v-for="(key, index1) in approvalList">
            <a-radio
              :value="key.value"
              :key="`copyParticipantsItem${index1}`"
              class="approver-selection"
              :disabled="copyHasInitiatorselect && key.value === 'initiatorselect'"
            >{{ key.label }}</a-radio>
          </template>
        </a-radio-group>
        <!-- 不同类型对应的内容 -->
        <!-- 部门负责人 -->
        <template v-if="item.participantType === 'depthead'">
          <div class="line"></div>
          <h3 class="yang">选择部门负责人</h3>
          <ReCodeSelect v-model="item.participants" :participantType="item.participantType" :options="deptheadList" />
        </template>
        <!-- 指定成员 -->
        <template v-if="item.participantType === 'assignperson'">
          <div class="line"></div>
          <h3 class="yang">添加成员</h3>
          <PerTreeSelect
            :perTreeValue="
              item.participants
              .map(PerTreeSelectItem => PerTreeSelectItem.participant)
            "
            placeholder="请选择人员"
            modeType="multiple"
            :replaceFields="{
              name: 'participantType',
              id: 'participants'
            }"
            @getUserInfo="
              PerTreeSelectFunc(...arguments,
              item.participants,
              item.participantType)"
          />
        </template>
        <!-- 发起人自选 -->
        <template v-if="
            item.participantType === 'initiatorselect' &&
            item.initiatorParticipants
          ">
          <div class="line"></div>
          <h3 class="yang">选择方式</h3>
          <a-radio-group v-model="item.initiatorSelectType">
            <a-radio v-for="(
                initiatorParticipantsItem, initiatorParticipantsIndex
              ) in initiatorSelectType" :value="initiatorParticipantsItem.value" :key="`copyInitiatorParticipantsItem${initiatorParticipantsIndex}`">{{ initiatorParticipantsItem.label }}</a-radio>
          </a-radio-group>
          <div class="line"></div>
          <h3 class="yang">可选范围</h3>
          <a-radio-group v-model="item.initiatorParticipants.participantType">
            <a-radio v-for="(
                initiatorParticipantsRangeItem, initiatorParticipantsRangeIndex
              ) in initiatorParticipantsRangeList" :value="initiatorParticipantsRangeItem.value" :key="`copyInitiatorParticipantsRangeItem${initiatorParticipantsRangeIndex}`">{{ initiatorParticipantsRangeItem.label }}</a-radio>
          </a-radio-group>
          <template v-if="item.initiatorParticipants.participantType === 'assignperson'">
            <div class="line"></div>
            <h3 class="yang">添加成员</h3>
            <UserSelect :echoData="item.initiatorParticipants.participants" @change="
                perModelConfirm(
                  ...arguments,
                  item.initiatorParticipants.participants,
                  item.initiatorParticipants.participantType
                )
              " />
          </template>
          <template v-if="item.initiatorParticipants.participantType === 'role'">
            <div class="line"></div>
            <h3 class="yang">添加角色</h3>
            <ReCodeSelect v-model="item.initiatorParticipants.participants" :participantType="item.initiatorParticipants.participantType" :options="approvalRoleList" />
          </template>
        </template>
        <!-- 汇报上级 -->
        <template v-if="item.participantType === 'superiors'">
          <div class="line"></div>
          <h3 class="yang">选择汇报上级</h3>
          <ReCodeSelect v-model="item.participants" :participantType="item.participantType" :options="reportSuperList" />
        </template>
        <template v-if="item.participantType === 'role'">
          <div class="line"></div>
          <h3 class="yang">选择角色</h3>
          <ReCodeSelect v-model="item.participants" :participantType="item.participantType" :options="approvalRoleList" />
        </template>
      </div>
    </a-card>
    <div class="approval-button">
      <a-button type="link" prefix="add-one" @click="addApproval('copy')">添加抄送人</a-button>
    </div>
    <!-- 抄送人结束 -->
  </section>
</template>

<script>
  import { getOrgMaxLayers } from '@/services/organization/framework.js'
  import { getFlowRoleList } from '@/services/logicFlow'
  export default {
    name: "Approval",
    props: ["value"],
    components: {
      UserSelect: () => import("../../component/userSelect"),
      ReCodeSelect: () => import("../../component/reCodeSelect"),
      PerTreeSelect: () => import('@/components/CbPerDepSelection/perTreeSelect')
    },
    data() {
      return {
        // 缓存一个备份儿数据，保存的时候再合并到当前数据
        cacheData: null,
        approvalList: [{
            label: "部门负责人",
            value: "depthead",
          },
          {
            label: "指定成员",
            value: "assignperson",
          },
          {
            label: "发起人自选",
            value: "initiatorselect",
          },
          {
            label: "汇报上级",
            value: "superiors",
          },
          {
            label: "角色",
            value: "role",
          },
        ],
        // 审批方式配置
        approvalTypeList: [{
            label: "会签审批（需要所有人同意）",
            value: 2,
          },
          {
            label: "或签审批（1人同意即可）",
            value: 1,
          },
          {
            label: "依次审批（按照选择顺序依次审批）",
            value: 0,
          },
        ],
        // 审批人为空时候的操作
        vacantSkipList: [{
            label: "自动通过",
            value: "autopass",
          },
          {
            label: "指定人员审批",
            value: "assignperson",
          },
          {
            label: "终止审批流",
            value: "stopapprove",
          },
          {
            label: "HR干预",
            value: "noskip",
          },
        ],
        // 审批人发起人相同时
        conflictTypeList: [{
            label: "由发起人对自己审批",
            value: "own",
          },
          {
            label: "自动跳过",
            value: "skip",
          },
          {
            label: "转交给汇报上级审批",
            value: "super",
          },
          {
            label: "转交给部门负责人审批",
            value: "depthead",
          },
        ],
        // 部门负责人
        deptheadList: [],
        // 汇报上级
        reportSuperList: [],
        // 审批角色
        approvalRoleList: [],
        // 审批人为发起人自选的时候
        initiatorSelectType: [{
            label: "单选",
            value: "single",
          },
          {
            label: "多选",
            value: "mult",
          },
        ],
        initiatorParticipantsRangeList: [{
            label: "全体人员",
            value: "all",
          },
          {
            label: "指定范围",
            value: "assignperson",
          },
          {
            label: "指定角色",
            value: "role",
          },
        ],
        canaddApproval: true
      };
    },
    computed: {
      // 审批人是否已经选择了发起人自选
      approvalHasInitiatorselect() {
        // 审批人
        let res = false
        const { assigneeParticipants } = this.cacheData.assigneeConfig
        for (let i = 0; i < assigneeParticipants.length; i++) {
          const item = assigneeParticipants[i]
          if (item.participantType === 'initiatorselect') {
            res = true
            break
          }
        }
        return res
      },
      // 抄送人是否已经选择了发起人自选
      copyHasInitiatorselect() {
        // 审批人
        let res = false
        const { ccParticipants } = this.cacheData.ccConfig
        for (let i = 0; i < ccParticipants.length; i++) {
          const item = ccParticipants[i]
          if (item.participantType === 'initiatorselect') {
            res = true
            break
          }
        }
        return res
      }
    },
    mounted() {
      // 初始化汇报上级
      this.initReportSuperList()
      // 初始化部门负责人
      this.initDeptheadList()
      // 初始化角色选择
      this.initApprovalRoleList()
      // 打开面板备份儿一分儿当前数据，面板关闭重置当前数据
      this.cacheData = JSON.parse(JSON.stringify(this.value))
      // 审批设置添加默认值
      const { approvalType, vacantSkip, conflictType } = this.cacheData.assigneeConfig
      if (approvalType === undefined || approvalType === '') {
        this.cacheData.assigneeConfig.approvalType = 2
      }
      !vacantSkip && (this.cacheData.assigneeConfig.vacantSkip = 'noskip')
      !conflictType &&  (this.cacheData.assigneeConfig.conflictType = 'depthead')
    },
    destroyed() {
      this.cacheData = null
    },
    methods: {
      initReportSuperList() {
        this.reportSuperList = []
        for (let i = 0; i < 20; i++) {
          this.reportSuperList.push({
            label: i === 0 ? '直接汇报上级' : `第${i + 1}级汇报上级`,
            value: i + 1 + ''
          })
        }
      },
      async initDeptheadList() {
        const res = await getOrgMaxLayers()
        this.deptheadList = []
        for (let i = 0; i < res.data; i++) {
          this.deptheadList.push({
            label: i === 0 ? "直接部门负责人" : `${i + 1}级部门负责人`,
            value: i + 1 + ''
          })
        }
      },
      async initApprovalRoleList() {
        const res = await getFlowRoleList({})
        this.approvalRoleList = res.data
        this.approvalRoleList.forEach((item)=> {
          item.label = item.flowRoleName
          item.value = item.flowRoleId
        })
      },
      addApproval(type) {
        const Obj = {
          participantType: "",
          participants: [],
        };
        if (type === "approval") {
          this.cacheData.assigneeConfig.assigneeParticipants.push(Obj);
        } else {
          this.cacheData.ccConfig.ccParticipants.push(Obj);
        }
      },
      delItem(index, type) {
        if (type === "approval") {
          if (this.cacheData.assigneeConfig.assigneeParticipants.length === 1) {
            return this.$message.warning("至少留存一个审批人");
          }
          this.cacheData.assigneeConfig.assigneeParticipants.splice(index, 1);
        } else {
          this.cacheData.ccConfig.ccParticipants.splice(index, 1);
        }
      },
      // 审批人为空的配置切换每次都吧指定人员审批的列表清空
      vacantSkipChange() {
        this.cacheData.assigneeConfig.vacantCandidateUser = []
      },
      /**
       * 审批人类型修改
       * @param evnet 当前被点击节点的数据
       * @param index 再当前所属数据项的第几项，方便定向修改数据
       * @param originName 数据来源【区分是审批人还是抄送人的数据】
       * @returns {string}
       */
      participantTypeChange(evnet, index, originName) {
        const type = evnet.target.value
        const obj = {
          assigneeConfig: 'assigneeParticipants',
          ccConfig: 'ccParticipants'
        }
        let item = this.cacheData[originName][obj[originName]][index]
        // 发起人自选
        if (type === "initiatorselect") {
          this.canaddApproval = false
          this.$set(this.cacheData[originName][obj[originName]], [index], {
            participantType: type,
            participants: [],
            initiatorSelectType: "",
            initiatorParticipants: {
              participantType: "",
              participants: []
            }
          })
        } else {
          this.canaddApproval = true
          this.$set(this.cacheData[originName][obj[originName]], [index], {
            participantType: type,
            participants: []
          })
        }
        this.$forceUpdate()
      },
      // 当审批人为发起人自选且可选范围为指定角色的时候
      initiatorParticipantsChange(info, type) {
        info.participantType = type;
        // info.participantName = type
        this.approvalRoleList.forEach((item) => {
          if (item.value === info.participant) {
            info.participantName = item.label
          }
        })
      },
      // 指定人员选择的回调
      PerTreeSelectFunc(userList, info) {
        info.length = 0
        // assignorg 组织  assignperson 人员
        userList.forEach((item, index) => {
          info[index] = {
            participantType: 'assignperson',
            participant: item.id,
            participantName: item.name
          }
        })
      },
      // 人员选择的确定弹窗
      perModelConfirm(userList, info) {
        info.length = 0
        // assignorg 组织  assignperson 人员
        userList.forEach((item, index) => {
          info[index] = {
            ...item,
            participantType: item.identification === 'dep' ? 'assignorg' : 'assignperson',
            participant: item.id,
            participantName: item.title
          }
        })
        this.PerDepModelVisible = false
      },
      // 1、label赋值 2、冗余的一份儿participantType赋值
      approvalContentSelect(participants, type) {
        this.deptheadList.forEach((item) => {
          if (item.value === participants.participant) {
            participants.participantName = item.label
          }
        });
        participants.participantType = type
      },
      approverNodeComfirm(callback) {
        if (this.infoVaild()) {
          // 查找结构里面所有审批人挂载的人员
          const { assigneeParticipants } =
          this.cacheData.assigneeConfig
          let approvalList = []
          assigneeParticipants.forEach((item) => {
            item.initiatorParticipants && approvalList.push('发起人自选')
            item.participants.forEach((key) => {
              if (key.participant || key.participant === 0) {
                approvalList.push(key.participantName)
              }
            })
          })
          // 查找抄送人里面的所有人员
          const { ccParticipants } = this.cacheData.ccConfig;
          let copyList = []
          ccParticipants.forEach((item) => {
            item.initiatorParticipants && copyList.push('发起人自选')
            item.participants.forEach((key) => {
              if (key.participant || key.participant === 0) {
                copyList.push(key.participantName)
              }
            })
          })
          this.cacheData.content = '审批人：' + approvalList.join(',')  + '&抄送人：' + copyList.join(',')
          callback(this.cacheData)
        } else {
          return false
        }
      },
      infoVaild() {
        // 表单校验
        const { approvalType, vacantSkip, conflictType, vacantCandidateUser } =
        this.cacheData.assigneeConfig;
        if (
          (!approvalType && approvalType !== 0) ||
          !vacantSkip ||
          !conflictType
        ) {
          this.$message.error("请完善审批人信息，审批人基础配置未完善");
          return false;
        }
        // 审批人空缺时候选择指定人员审批
        if (
          vacantSkip === "assignperson" &&
          !vacantCandidateUser.length > 0
        ) {
          this.$message.error("请完善审批人为空时的候选人列表");
          return false;
        }
        return this.approvalVaild(approvalType) && this.copyVaild(approvalType)
      },
      // 审批人校验
      approvalVaild(approvalType) {
        const { assigneeParticipants } = this.cacheData.assigneeConfig
        let vaildRes = true
        let selectPerTotalNum = 0
        // 审批人类型为发起人自选的时候
        for (let i = 0; i < assigneeParticipants.length; i++) {
          let item = assigneeParticipants[i];
          // 未选择审批人类型
          if (!item.participantType) {
            this.$message.error("请完善审批人信息，未选择审批人类型");
            vaildRes = false;
            break;
          }
          // 发起人自选
          if (item.participantType === "initiatorselect") {
            // 选择方式（单选/多选）未选择
            if (!item.initiatorSelectType) {
              this.$message.error(
                "请完善审批人信息，选择方式（单选/多选）未选择"
              );
              vaildRes = false;
              break;
            }
            // 选择范围未选择
            if (!item.initiatorParticipants.participantType) {
              this.$message.error("请完善审批人信息，选择范围未选择");
              vaildRes = false;
              break;
            }
            // 可选范围是指定成员，并且未选择人员
            if (
              item.participantType === "assignperson"
            ) {
              this.$message.error(
                "请完善审批人信息，可选范围是指定成员，并且未选择人员"
              );
              vaildRes = false;
              break;
            }
          } else {
            // 其他类型，未选择审批人
            if ( item.participants.length === 0 ) {
              this.$message.error("请完善审批人信息，其他类型，未选择审批人");
              vaildRes = false;
              break;
            }
            selectPerTotalNum += item.participants.length
            console.log('xxxxxxxx', selectPerTotalNum)
            if (selectPerTotalNum > 10) {
              this.$message.error("当前节点审批人最多10人！");
              vaildRes = false;
              break;
            }
          }
        }
        return vaildRes;
      },
      // 抄送人校验信息
      copyVaild(approvalType) {
        const { ccParticipants } = this.cacheData.ccConfig;
        let vaildRes = true;
        // 抄送人类型为发起人自选的时候
        for (let i = 0; i < ccParticipants.length; i++) {
          let item = ccParticipants[i];
          // 未选择抄送人类型
          if (!item.participantType) {
            this.$message.error("请完善抄送人信息，未选择抄送人类型");
            vaildRes = false;
            break;
          }
          // 发起人自选
          if (item.participantType === "initiatorselect") {
            // 选择方式（单选/多选）未选择
            if (!item.initiatorSelectType) {
              this.$message.error(
                "请完善抄送人信息，选择方式（单选/多选）未选择"
              );
              vaildRes = false;
              break;
            }
            // 选择范围未选择
            if (!item.initiatorParticipants.participantType) {
              this.$message.error("请完善抄送人信息，选择范围未选择");
              vaildRes = false;
              break;
            }
            // 可选范围是指定成员，并且未选择人员
            if (
              item.participantType === "assignperson" &&
              !item.initiatorParticipants.participants[0].participant
            ) {
              this.$message.error(
                "请完善抄送人信息，可选范围是指定成员，并且未选择人员"
              );
              vaildRes = false;
              break;
            }
          } else {
            // 其他类型，未选择抄送人
            if (
              item.participants.length === 0
              // !item.participants[0].participant &&
              // item.participants[0].participant !== 0
            ) {
              this.$message.error("请完善抄送人信息，其他类型，未选择抄送人");
              vaildRes = false;
              break;
            }
          }
        }
        return vaildRes;
      }
    }
  }

</script>
<style lang="less" scoped>
  .cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: @sc-grey-100;
    .font-size(14px);
    .iconpark-icon {
      width: 16px;
      height: 16px;
      color: @sc-error-100;
      cursor: pointer;
    }
  }
  .approver-container {
    display: flex;
    .approver-set {
      display: inline-block;
      color: @sc-grey-100;
      .font-size(14px);
      margin-bottom: 14px;
    }
  }
  .approval-but {
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid @sc-primary-100;
    margin-bottom: 14px;
    margin-top: -2px;
  }
  .approver-setting {
    display: inline-block;
    margin-right: 10px;
    color: @sc-grey-100;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 14px;
  }
  .border-line {
    margin-top: 15px;
    display: flex;
  }
  .approval-button {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .approver-selection {
    width: 110px;
    .font-size(14px);
    color: @sc-grey-100;
  }
  .approver-selectss {
    width: 112px;
    .font-size(14px);
    color: @sc-grey-100;
  }
  .approver-selections {
    width: 160px;
    .font-size(14px);
    color: @sc-grey-100;
  }
  .approver-selects {
    width: 100%;
    .font-size(14px);
    color: @sc-grey-100;
  }
  /deep/ .ant-card-head {
    width: 100%;
    min-height: 34px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    background-color:  @sc-greyBg-10;
  }
  /deep/ .ant-card {
    border: 1px solid @sc-greyBg-20 !important;
    box-shadow: 0 0 0 0;
    border-radius: 4px;
    margin-top: 20px;
    &:first-child{
      margin: 0;
    }
    .ant-card-head-wrapper{
      width: 100%;
    }
  }
  .top-height {
    margin-top: 2px;
    .font-size(14px);
    color: @sc-grey-100;
  }
  .line {
    margin-top: 14px;
    width: 100%;
    height: 1px;
    background-color: rgba(143, 147, 161, 0.3);
  }
  .lines {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    margin-bottom: 30px;
  }
  .yang {
    .font-size(14px);
    color: @sc-grey-100;
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .approv {
    width: 100%;
    height: 34px;
    color: @sc-grey-100;
    .font-size(14px);
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px solid @sc-greyBg-20;
  }
  .approv-body {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 20px;
  }
  .seconed-line {
    width: 420px;
    height: 1px;
    background-color: rgba(143, 147, 161, 0.3);
    margin-left: -20px;
  }
  .appro {
    margin-top: 17px;
    margin-bottom: 10px;
    color: @sc-grey-100;
    .font-size(14px);
  }
  /deep/ .a-button {
    font-size: 14px;
  }
/deep/ .ant-card-body {
  padding: 20px 12px 16px;
}
.approv-type {
  border: 1px solid @sc-greyBg-20;
  border-radius: 4px;
}
/deep/ .ant-card-head-title {
  padding: 0;
}
/deep/ .ant-radio-wrapper {
  margin-bottom: 12px;
}
</style>
