<template>
  <section class="condition-pane">
    <div class="content" v-if="cacheData">
      <template
        v-for="(conditionGroupsItem, index) in cacheData.conditionGroups"
      >
        <div v-if="index > 0" :key="`conditionGroup${index}`" class="groupWord">或</div>
        <a-card :key="`conditionGroups${index}`" title="条件组" class="box-card">
          <a-popconfirm
            slot="extra"
            title="确定要删除当前条件组吗?"
            ok-text="是"
            cancel-text="否"
            placement="bottomRight" 
            @confirm="delItem(index)"
          >
            <a-button type="link" class="delete">
              <svg class="iconpark-icon"><use href="#delete"></use></svg>
            </a-button>
          </a-popconfirm>
          <template v-for="(GroupsItem, index1) in conditionGroupsItem.details">
            <div :key="`GroupsItem${index1}`" class="text item">
              <div class="group-item-start">
                <span class="group-item-title">{{ index1 === 0 ? "当" : "且" }}</span>
                <template v-if="index1 !== 0">
                  <a-popconfirm
                    title="确定要删除当前条件吗?"
                    placement="bottomRight"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="delConditionItem(index ,index1)"
                  >
                    <span class="group-item-del">删除</span>
                  </a-popconfirm>
                </template>
              </div>
              <!-- 类型选择 -->
              <a-select v-model="GroupsItem.referName" class="w100" @change="conditionTypeChange(GroupsItem)">
                <a-select-option
                  v-for="item in conditionSelect"
                  :key="item.referName"
                  :value="item.referName"
                >
                  {{ item.conditionName }}
                </a-select-option>
              </a-select>
              <!-- 条件模块儿 -->
              <div v-if="[...calcConditonTypeList, ...matchConditonTypeList].includes(GroupsItem.type)" class="group-item-condition">
                <!-- 当选项为单选或者多选的时候展示的 -->
                <template
                  v-if="matchConditonTypeList.includes(GroupsItem.type)"
                >
                  <a-select 
                    v-model="GroupsItem.matchRule"
                    @change="matchRuleChange(...arguments, GroupsItem, 'matchRuleList')" 
                    class="w100"
                  >
                    <a-select-option
                      v-for="item in matchRuleList"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </template>
                <!-- 数字、金额、汇总、时长 -->
                <template
                  v-else-if="calcConditonTypeList.includes(GroupsItem.type)"
                >
                  <!-- 条件 -->
                  <a-select
                    v-model="GroupsItem.conditionRange" 
                    @change="conditiionRnageChange(...arguments, GroupsItem, 'conditionRangeOptions')" 
                    class="w100"
                  >
                    <a-select-option
                      v-for="(item, index3) in conditionRangeOptions"
                      :key="`conditionRangeOptions${index3}`"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </template>
              </div>

              <!-- 内容区域 -->
              <div class="group-item-content" v-if="GroupsItem.type">
                <!-- 发起人 -->
                <template v-if="GroupsItem.type === 'startPerson'">
                  <PerTreeSelect
                    :perTreeValue="GroupsItem.content"
                    modeType="multiple"
                    @getUserInfo="selectChange(...arguments, true, GroupsItem, 'name', 'id')"
                  />
                </template>
                <!-- 部门 -->
                <template v-if="['org', 'WKTransferTransferDept'].includes(GroupsItem.type)">
                  <DepTreeSelect
                    :treeSelectValue="GroupsItem.content"
                    multiple
                    @getValueSelect="selectChange(...arguments, true, GroupsItem, 'label', 'value', 'object')"
                  />
                </template>
                <!-- 岗位 -->
                <template v-if="['post', 'WKTransferTransferPost'].includes(GroupsItem.type)">
                  <Post
                    v-model="GroupsItem.content"
                    mode="multiple"
                    @getPostValue="selectChange(...arguments, true, GroupsItem)"
                  />
                </template>
                <!-- 职级 -->
                <template v-if="['rank', 'WKTransferTransferJob'].includes(GroupsItem.type)">
                  <Rank
                    v-model="GroupsItem.content"
                    mode="multiple"
                    @getRankValue="selectChange(...arguments, true, GroupsItem)"
                  />
                </template>
                <!-- 职务、工作地点、员工状态、合同类型、合同主体 -->
                <template
                  v-if="
                    [
                      'duty',
                      'work_address',
                      'staff_status',
                      'contract_type',
                      'contract_company',
                      'engage_mode'
                    ].includes(GroupsItem.type)
                  "
                >
                  <DictionariesInput
                    :dictionariesValue="GroupsItem.content"
                    :mode="GroupsItem.parameter === 'duty' ? 'default' : 'multiple'"
                    @getData="selectChange(...arguments, true, GroupsItem)"
                    :parameter="GroupsItem.parameter"
                  />
                </template>
                <!-- 单选多选 -->
                <template
                  v-if="
                    ['WKMultiSelect', 'WKSelect'].includes(
                      GroupsItem.type
                    )
                  "
                >
                  <a-select
                    v-model="GroupsItem.content"
                    class="w100"
                    :mode="GroupsItem.type === 'WKMultiSelect' ? 'multiple' : 'default' "
                    @change="conditionContentChange(...arguments, GroupsItem)"
                  >
                    <a-select-option
                      v-for="item in optionList[GroupsItem.referName] || []"
                      :key="item.referName"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </template>
                <!-- 数字、金额、子表、日期区间, 计算属性 -->
                <template
                  v-if="calcConditonTypeList.includes(GroupsItem.type)"
                >
                  <NumberInput
                    v-model="GroupsItem.content"
                    :precision="1"
                    :decimalSeparator="GroupsItem.type === 'WKDateRange'"
                    @change="(val) => GroupsItem['contentName'] = val "
                  >
                    <span v-if="GroupsItem.element" slot="addonAfter">{{ GroupsItem.element }}</span>
                  </NumberInput>
                </template>
                <!-- 针对套件中可以作为条件字段，并且数据源是远程拉取的子弹展示的内容，不包含部门、岗位、职级 -->
                <template 
                  v-if="dataEchoConditonTypeList.includes(GroupsItem.type)
                  && !['WKTransferTransferDept',
                  'WKTransferTransferPost',
                  'WKTransferTransferJob'].includes(GroupsItem.type)"
                >
                  <DictionariesInput
                    :dictionariesValue="GroupsItem.content"
                    @getData="selectChange(...arguments, GroupsItem, 'string')"
                    :parameter="GroupsItem.parameter"
                  />
                </template>
              </div>
            </div>
          </template>
          <a-button
            type="link"
            prefix="add-one"
            @click="addCondition(conditionGroupsItem.details)"
            class="addConditionBtn"
            >添加条件</a-button>
        </a-card>
      </template>
      <a-button
        type="link"
        prefix="add-one"
        @click="addConditionGroup(cacheData.conditionGroups)"
        class="addGroupBtn"
        >添加条件分组</a-button
      >
    </div>
  </section>
</template>

<script>
import CbPerDepSelection from '@/components/CbPerDepSelection'
import { parameterTypeList } from '@/components/kFormDesign/packages/core/suite.js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: "Condition",
  props: ["formData", "value"],
  mixins: [CbPerDepSelection],
  components: {
    DictionariesInput: () => import("@/components/CbDropDownInput/dictionariesInput"),
    Post: () => import("@/components/CbDropDownInput//post"),
    Rank: () => import("@/components/CbDropDownInput//rank"),
    NumberInput: () => import('@/components/CbNumber')
  },
  data() {
    return {
      // 备份下作为条件字段的初始值，用来最后计算当前是减少了还是增加了
      catchFormConditionKeyList: {},
      // 缓存一个备份儿数据，保存的时候再合并到当前数据
      cacheData: null,
      // 匹配规则
      matchRuleList: [
        {
          label: "完全匹配",
          value: "all"
        },
        {
          label: "包含以下任意",
          value: "either"
        }
      ],
      // 条件范围
      conditionRangeOptions: [
        {
          label: "等于",
          value: "=",
        },
        {
          label: "大于",
          value: ">",
        },
        {
          label: "小于",
          value: "<",
        },
        {
          label: "大于等于",
          value: ">=",
        },
        {
          label: "小于等于",
          value: "<=",
        },
      ],
      userInfoList: [
        {
          conditionName: "发起人",
          referName: "initiatorId",
          referType: 'simple',
          type: "startPerson"
        },
        {
          conditionName: "部门",
          referName: "orgId",
          referType: 'simple',
          type: "org"
        },
        {
          conditionName: "岗位",
          referName: "postId",
          referType: 'simple',
          type: "post"
        },
        {
          conditionName: "职级",
          referName: "jobGradeId",
          referType: 'simple',
          type: "rank"
        },
        // {
        //   conditionName: "职务",
        //   referName: "duty",
        //   referType: 'simple',
        //   type: "duty",
        //   parameter: 'duty'
        // },
        {
          conditionName: "工作地点",
          referName: "workplaceId",
          referType: 'simple',
          type: "work_address",
          parameter: 'work_address'
        },
        {
          conditionName: "员工状态",
          referName: "staffStatus",
          referType: 'simple',
          type: "staff_status",
          parameter: 'staff_status'
        },
        // {
        //   conditionName: "合同类型",
        //   referName: "contract_type",
        //   type: "contract_type",
        //   parameter: 'contract_type'
        // },
        {
          conditionName: "聘用形式",
          referName: "engageMode",
          referType: 'simple',
          type: "engage_mode",
          parameter: 'engage_mode'
        },
        {
          conditionName: "合同主体",
          referName: "contactCo",
          referType: 'simple',
          type: "contract_company",
          parameter: 'contract_company'
        },
      ],
      // 条件部分的配置
      // 时长的字段集合
      dataRangeKeyList: [],
      // 展示的是大于小于等于这种的类型
      fixCalcConditonTypeList: ['WKNumber', 'WKAmount', 'WKDateRange', 'WKAutoCompute'],
      dataCalcConditonTypeList: [],
      // 展示的是完全匹配和部分包含这种的条件的【单选的不展示】
      matchConditonTypeList: ['WKMultiSelect', 'duty'],
      // 下拉框单选的不展示条件选择，但是需要默认填充条件的值
      // 固定组件的需要补充的
      fixEchoConditonTypeList: ['startPerson', 'org', 'post', 'rank', 'work_address', 
      'staff_status', 'contract_type', 'engage_mode', 'contract_company', 'WKSelect'],
      // 套件里面需要补充的
      dataEchoConditonTypeList: [],
      // 设计数据中带过来的下拉框的数据集合
      optionList: {}
    }
  },
  computed: {
    ...mapGetters('designer', ['getFormConditionKeyList']),
    conditionSelect() {
      const options = JSON.parse(JSON.stringify(this.userInfoList))
      options.forEach(item => {
        item['conditionType'] = this.echoConditonTypeList.includes(item.type) ? 'matchRule' : 'conditionRange'
      })
      // 这里面冗余一些跟人属性相关的条件
      const allowType = [
        "WKWordTable",
        ...this.fixCalcConditonTypeList,
        ...this.matchConditonTypeList,
        ...this.fixEchoConditonTypeList
      ]
      this.formData &&
        this.formData.forEach((item) => {
          // 常规组件组合条件的逻辑
          if (allowType.includes(item.type)) {
            if (item.type === "WKWordTable") {
              item.list.forEach(key => {
                if (allowType.includes(key.type) && key.options.summari) {
                  // 收集设计数据中的下拉数据的配置
                  if (key.options.options) {
                    this.optionList[`${item.key}.${key.key}`] = key.options.options
                  }
                  options.push({
                    type: key.type,
                    key: item.key,
                    conditionName: `${item.options.baseInfoList[0].label}.${
                      key.options.baseInfoList[
                        key.options.baseInfoList.length === 1 ? 0 : 2
                      ].label
                    }`,
                    // 条件类型，后台用来判断是大于小于还是完全匹配这种的
                    conditionType: this.echoConditonTypeList.includes(key.type) ? 'matchRule' : 'conditionRange',
                    // 后台获取移动端数据用的字段
                    referName: key.options.baseInfoList.length === 1 ?  `${item.key}.${key.key}` : `${item.key}.${key.key}${key.options.baseInfoList[2].model}`,
                    // 内容的类型，后台取值用的
                    referType: key.options.baseInfoList[0].dataType,
                    element: key.options.companyVal
                  })
                }
              })
            } else {
              // 收集设计数据中的下拉数据的配置
              if (item.options.options) {
                this.optionList[`${item.key}`] = item.options.options
              }
              options.push({
                // 暂时只有时间区间是取第三个时长的label
                type: item.type,
                key: item.key,
                conditionName:
                  item.options.baseInfoList[
                    item.options.baseInfoList.length === 1 ? 0 : 2
                  ].label,
                conditionType: this.echoConditonTypeList.includes(item.type) ? 'matchRule' : 'conditionRange',
                referName: item.options.baseInfoList.length === 1 ? item.key : `${item.key}.${item.options.baseInfoList[2].model}`,
                referType: item.options.baseInfoList[0].dataType,
                element: item.options.companyVal
              })
            }
          }
          // 套件的拆解逻辑
          if (item.subType === 'suite') {
            item.options.baseInfoList.forEach(key => {
              if (key.condition) {
                options.push({
                  key: item.key,
                  type: `WK${this.firstToUpper(item.model)}${this.firstToUpper(key.model)}`,
                  conditionName: key.label,
                  conditionType: this.echoConditonTypeList.includes(key.type) ? 'matchRule' : 'conditionRange',
                  referName: `${item.model}.${key.model}`,
                  referType: key.dataType,
                  parameter: key.parameter,
                  element: key.element
                })
                if (key.subDataType === 'object') {
                  this.dataEchoConditonTypeList.push(`WK${this.firstToUpper(item.model)}${this.firstToUpper(key.model)}`)
                }
                if (key.subDataType === 'simple') {
                  this.dataCalcConditonTypeList.push(`WK${this.firstToUpper(item.model)}${this.firstToUpper(key.model)}`)
                }
              }
            })
          }
        })
      return options;
    },
    echoConditonTypeList() {
      return [...this.fixEchoConditonTypeList, ...this.dataEchoConditonTypeList]
    },
    calcConditonTypeList() {
      return [...this.fixCalcConditonTypeList, ...this.dataCalcConditonTypeList]
    },
    userInfoReferNameList() {
      return this.userInfoList.map(item => item.referName)
    }
  },
  mounted() {
    // 打开面板备份儿一分儿当前数据，面板关闭重置当前数据,保存的时候list会join(',')，初始化需要格式化成list
    const parameterList = [...parameterTypeList(), ...this.userInfoList]
    const parameterObj = {}
    this.userInfoList.forEach(item => {
      parameterObj[`${item.type}`] = item.parameter
    })
    parameterTypeList().forEach(item => {
      parameterObj[`WK${this.firstToUpper(item.type)}`] = item.parameter
    })
    let allData = JSON.parse(JSON.stringify(this.value))
    allData.conditionGroups.forEach(item => {
      item.details.forEach(key => {
        if (!this.userInfoReferNameList.includes(key.referName)) {
          if (this.catchFormConditionKeyList[key.referName]) {
            this.catchFormConditionKeyList[key.referName] -= 1
          } else {
            this.catchFormConditionKeyList[key.referName] = -1
          }
        }
        if (key.content && typeof key.content === 'string' && key.content.indexOf(',') > -1) {
          // 单独处理【部门】回显的问题
          if (key.referName === 'orgId') {
            key.content = key.content.split(',').map(item => {
              return {
                value: item
              }
            })
          } else {
            key.content = key.content.split(',')
          }
        }
        if (parameterObj[key.type]) {
          key['parameter'] = parameterObj[key.type]
        }
      })
    })
    this.cacheData = allData
  },
  destroyed() {
    this.cacheData = null;
  },
  methods: {
    ...mapMutations('designer', ['setFormConditionKeyList']),
    firstToUpper(str) {
      return str.trim().replace(str[0], str[0].toUpperCase())
    },
    // 当前条件的类型是否需要展示条件字段
    conditionIsShow(info) {
      const normalShowTypeList = ['WKMultiSelect', 'WKSelect', 'duty', 'WKNumber', 'WKAmount', 'WKDateRange']
      const suiteshowTypeList = ['WKLeaveType']
    },
    // 删除条件组
    delItem(index) {
      if (this.cacheData.conditionGroups.length === 1) {
        return this.$message.warning('请最少保留一个条件组')
      }
      if (this.cacheData.conditionGroups){
        this.cacheData.conditionGroups.splice(index, 1)
      }
    },
    // 删除条件组内一个条件
    delConditionItem(groupIndex ,index) {
      // 条件删除
      this.cacheData.conditionGroups[groupIndex].details.splice(index, 1)
    },
    conditionTypeChange(info) {
      info.content = undefined
      info.contentName = undefined
      info.matchRule = undefined
      info.conditionRange = undefined
      info.mathOrRangeName = undefined
      this.conditionSelect.forEach((item) => {
        if (item.referName === info.referName) {
          info.referType = item.referType
          info.conditionType = item.conditionType
          info.conditionName = item.conditionName
          info.element = item.element
          info.type = item.type
          item.parameter && (info['parameter'] = item.parameter)
        }
      });
    },
    // 条件切换  listType 当前应该被循环的数据项是conditionRangeOptions或者matchRuleList
    matchRuleChange(value, e, info, listType) {
      this[listType].forEach(item => {
        if (item.value === value) {
          info.mathOrRangeName = item.label
          info.conditiionRnage = undefined
        }
      })
      this.$forceUpdate()
    },
    conditiionRnageChange(value, e, info, listType) {
      this[listType].forEach(item => {
        if (item.value === value) {
          info.mathOrRangeName = item.label
          info.matchRule = undefined
        }
      })
      this.$forceUpdate()
    },
    // 岗位和职级的内容选择
    assignmentFunc(item, name) {
      item.contentName = name;
    },
    // 发起人自身属性被选中后选择的内容的数据格式化
    // valueType string object
    selectChange(value, isMultiple, info, labelKey = 'label', valueKey = 'value', type) {
      let valueList = []
      let labelList = []
      if (value && value instanceof Array) {
        value.forEach(item => {
          labelList.push(item[labelKey])
          if (type === 'object') {
            valueList.push({ value: item[valueKey] })
          } else {
            valueList.push(item[valueKey])
          }
        })
        info.contentName = labelList.join(',')
        info.content = valueList
        this.$forceUpdate()
      }
    },
    // 单选多选的内容切换
    conditionContentChange(value, e, info) {
      if (value instanceof Array) {
        let valueList = []
        let labelList = []
        this.optionList[info.referName].forEach((item) => {
          if (value.includes(item.value)) {
            labelList.push(item.label)
          }
        })
        info["contentName"] = labelList.join(',')
      } else {
        this.optionList[info.referName].forEach((item) => {
          if (item.value === info.content) {
            info["contentName"] = item.label;
          }
        })
      }      
      this.$forceUpdate()
    },
    // 添加条件
    addCondition(details) {
      details.push({
        conditionType: undefined,
        conditionName: undefined,
        referName: undefined,
        content: undefined,
        contentName: undefined,
        element: undefined,
        conditionRange: undefined,
        matchRule: undefined,
        // 条件的展示名称
        mathOrRangeName: undefined,
        sortNum: 0,
        type: "string",
      });
    },
    addConditionGroup(conditionGroups) {
      conditionGroups.push({
        details: [
          {
            conditionType: undefined,
            conditionName: undefined,
            referName: undefined,
            content: undefined,
            contentName: undefined,
            element: undefined,
            conditionRange: undefined,
            matchRule: undefined,
            // 条件的展示名称
            mathOrRangeName: undefined,
            sortNum: 0,
            type: "string",
          },
        ],
      });
    },
    conditionNodeComfirm(callback) {
      if (this.conditionvaild()) {
        const mergeConditionKeyList = {}
        // 拼接展示内容
        this.cacheData.content = ''
        this.cacheData.conditionGroups.forEach((item, index) => {
          let str = index === 0 ? '' : '或'
          item.details.forEach((key, detailsIndex) => {
            if (!this.userInfoReferNameList.includes(key.referName)) {
              if (this.catchFormConditionKeyList[key.referName]) {
                this.catchFormConditionKeyList[key.referName] += 1
              } else {
                // 原有条件张不存在的添加并计数
                if (mergeConditionKeyList[key.referName]) {
                  mergeConditionKeyList[key.referName] += 1
                } else {
                  mergeConditionKeyList[key.referName] = 1
                }
              }
            }
            str += `${detailsIndex === 0 ? "当" : "且"}${
              key.conditionName
            }
            ${ key.mathOrRangeName ? key.mathOrRangeName : this.findConditionName(key.matchRule || key.conditionRange) }
            ${ key.contentName }${key.element || ""}`
            if (key.content instanceof Array) {
              if (typeof key.content[0] === 'string') {
                key.content = key.content.join(',')
              } else {
                key.content = key.content.map(contentItem => {
                  return contentItem.value
                }).join(',')
              }
            }
          })
          this.cacheData.content += str
        })
        const copyFormConditionKeyList = JSON.parse(JSON.stringify(this.getFormConditionKeyList))
        // 吧计算好的作为条件字段的数量
        Object.entries(Object.assign(mergeConditionKeyList, this.catchFormConditionKeyList)).forEach(item => {
          let key = item[0], value = item[1]
          if (copyFormConditionKeyList[key]) {
            copyFormConditionKeyList[key] += value
          } else {
            copyFormConditionKeyList[key] = value
          }
        })
        this.setFormConditionKeyList(copyFormConditionKeyList)
        callback(this.cacheData)
      } else {
        this.$message.error("请全部填充条件项")
      }
    },
    // 查找对应字段的名称
    findConditionName(value) {
      const allConditionList = [
        ...this.conditionRangeOptions,
        ...this.matchRuleList
      ]
      let res
      allConditionList.forEach((item) => {
        if (item.value === value) {
          res = item.label
        }
      });
      return res
    },
    conditionvaild() {
      const { conditionGroups } = this.cacheData
      let vaildRes = true
      let conditionItem;
      const errMsg = []
      for (let i = 0; i < conditionGroups.length; i++) {
        conditionItem = conditionGroups[i].details;
        for (let j = 0; j < conditionItem.length; j++) {
          // 以下几个选择需要默认填写条件
          // 套件中的需要包含
          if (this.dataEchoConditonTypeList.includes(conditionItem[j].type)) {
            conditionItem[j].matchRule = "all"
          }
          if (this.fixEchoConditonTypeList.includes(conditionItem[j].type)) {
            conditionItem[j].matchRule = "either"
          }
          const { referName, content, conditionRange, matchRule, conditionType } =
            conditionItem[j]
          if (!(referName && content && (conditionRange || matchRule) && conditionType)) {
            this.$message.warning(`${conditionItem[j].conditionName}：条件不完善`)
            vaildRes = false
            break
          }
        }
        if (!vaildRes) break
      }
      return vaildRes
    }
  }
}
</script>

<style lang="less" scoped>
.w100{
  width: 100%;
}
/deep/.ant-card-head{
  min-height: auto!important;
  background-color: rgba(143, 147, 161, 0.1);
  padding: 7px 12px;
}
/deep/.ant-card-head-title{
  padding: 0;
}
/deep/.ant-card-extra{
  padding: 0;
}
/deep/.ant-card-body{
  padding: 0 12px;
}
.groupWord{
  margin-bottom: 14px;
}
.box-card {
  margin-bottom: 14px;
  &:last-child{
    margin-bottom: 0;
  }
}
.headerBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f4f6;
  padding: 0 12px;
}
.cardHeaderTitle{
  font-size: 16px;
  color: #202a40;
  line-height: 24px;
}
.cardHeaderDel{
  margin: 0 0 0 76%;
  color: rgba(143, 147, 161, 0.6);
  font-size: 16px;
}
.addConditionBtn{
  margin: 14px 0 !important;
  .anticon{
    margin-right: 4px;
  }
}
.group-item-start{
  font-size: 14px;
  font-weight: normal;
  color: rgba(32, 42, 64, 0.6);
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .group-item-del{
    cursor: pointer;
    color: @sc-error-100;
  }
}
.group-item-condition, .group-item-content{
  margin-top: 24px;
}
.addGroupBtn{
  .anticon{
    margin-right: 4px;
  }
}
</style>
