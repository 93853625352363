var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cacheData
    ? _c(
        "section",
        { staticClass: "approver-pane", staticStyle: { height: "100%" } },
        [
          void 0,
          _vm._l(
            _vm.cacheData.assigneeConfig.assigneeParticipants,
            function (item, index) {
              return _c("a-card", { key: "assigneeParticipants" + index }, [
                _c(
                  "div",
                  {
                    staticClass: "cardHeader",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("span", [_vm._v("审批人选择")]),
                    _vm.cacheData.assigneeConfig.assigneeParticipants.length > 1
                      ? _c(
                          "svg",
                          {
                            staticClass: "iconpark-icon",
                            on: {
                              click: function ($event) {
                                return _vm.delItem(index, "approval")
                              },
                            },
                          },
                          [_c("use", { attrs: { href: "#delete" } })]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "approvalItem" },
                  [
                    _c(
                      "a-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.participantTypeChange.apply(
                              void 0,
                              argsArray.concat([index], ["assigneeConfig"])
                            )
                          },
                        },
                        model: {
                          value: item.participantType,
                          callback: function ($$v) {
                            _vm.$set(item, "participantType", $$v)
                          },
                          expression: "item.participantType",
                        },
                      },
                      [
                        _vm._l(_vm.approvalList, function (key, index1) {
                          return [
                            _c(
                              "a-radio",
                              {
                                key: "assigneeParticipantsItem" + index1,
                                staticClass: "approver-selection",
                                attrs: {
                                  value: key.value,
                                  disabled:
                                    _vm.cacheData.assigneeConfig
                                      .assigneeParticipants.length > 1 &&
                                    key.value === "initiatorselect",
                                },
                              },
                              [_vm._v(_vm._s(key.label))]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    item.participantType === "depthead"
                      ? [
                          _c("div", { staticClass: "line" }),
                          _c("h3", { staticClass: "yang" }, [
                            _vm._v("选择部门负责人"),
                          ]),
                          _c("ReCodeSelect", {
                            attrs: {
                              participantType: item.participantType,
                              options: _vm.deptheadList,
                            },
                            model: {
                              value: item.participants,
                              callback: function ($$v) {
                                _vm.$set(item, "participants", $$v)
                              },
                              expression: "item.participants",
                            },
                          }),
                        ]
                      : _vm._e(),
                    item.participantType === "assignperson"
                      ? [
                          _c("div", { staticClass: "line" }),
                          _c("h3", { staticClass: "yang" }, [
                            _vm._v("添加成员"),
                          ]),
                          _c("PerTreeSelect", {
                            attrs: {
                              perTreeValue: item.participants.map(function (
                                PerTreeSelectItem
                              ) {
                                return PerTreeSelectItem.participant
                              }),
                              placeholder: "请选择人员",
                              modeType: "multiple",
                              replaceFields: {
                                name: "participantType",
                                id: "participants",
                              },
                            },
                            on: {
                              getUserInfo: function ($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.PerTreeSelectFunc.apply(
                                  void 0,
                                  argsArray.concat(
                                    [item.participants],
                                    [item.participantType]
                                  )
                                )
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                    item.participantType === "initiatorselect" &&
                    item.initiatorParticipants
                      ? [
                          _c("div", { staticClass: "line" }),
                          _c("h3", { staticClass: "yang" }, [
                            _vm._v("选择方式"),
                          ]),
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: item.initiatorSelectType,
                                callback: function ($$v) {
                                  _vm.$set(item, "initiatorSelectType", $$v)
                                },
                                expression: "item.initiatorSelectType",
                              },
                            },
                            _vm._l(
                              _vm.initiatorSelectType,
                              function (
                                initiatorParticipantsItem,
                                initiatorParticipantsIndex
                              ) {
                                return _c(
                                  "a-radio",
                                  {
                                    key:
                                      "initiatorParticipantsItem" +
                                      initiatorParticipantsIndex,
                                    attrs: {
                                      value: initiatorParticipantsItem.value,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(initiatorParticipantsItem.label)
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                          _c("div", { staticClass: "line" }),
                          _c("h3", { staticClass: "yang" }, [
                            _vm._v("可选范围"),
                          ]),
                          _c(
                            "a-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  item.initiatorParticipants.participants = []
                                },
                              },
                              model: {
                                value:
                                  item.initiatorParticipants.participantType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.initiatorParticipants,
                                    "participantType",
                                    $$v
                                  )
                                },
                                expression:
                                  "item.initiatorParticipants.participantType",
                              },
                            },
                            _vm._l(
                              _vm.initiatorParticipantsRangeList,
                              function (
                                initiatorParticipantsRangeItem,
                                initiatorParticipantsRangeIndex
                              ) {
                                return _c(
                                  "a-radio",
                                  {
                                    key:
                                      "initiatorParticipantsRangeItem" +
                                      initiatorParticipantsRangeIndex,
                                    attrs: {
                                      value:
                                        initiatorParticipantsRangeItem.value,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        initiatorParticipantsRangeItem.label
                                      )
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                          item.initiatorParticipants.participantType ===
                          "assignperson"
                            ? [
                                _c("div", { staticClass: "line" }),
                                _c("h3", { staticClass: "yang" }, [
                                  _vm._v("添加成员"),
                                ]),
                                _c("UserSelect", {
                                  attrs: {
                                    echoData:
                                      item.initiatorParticipants.participants,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var i = arguments.length,
                                        argsArray = Array(i)
                                      while (i--) argsArray[i] = arguments[i]
                                      return _vm.perModelConfirm.apply(
                                        void 0,
                                        argsArray.concat(
                                          [
                                            item.initiatorParticipants
                                              .participants,
                                          ],
                                          [
                                            item.initiatorParticipants
                                              .participantType,
                                          ]
                                        )
                                      )
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                          item.initiatorParticipants.participantType === "role"
                            ? [
                                _c("div", { staticClass: "line" }),
                                _c("h3", { staticClass: "yang" }, [
                                  _vm._v("添加角色"),
                                ]),
                                _c("ReCodeSelect", {
                                  attrs: {
                                    participantType:
                                      item.initiatorParticipants
                                        .participantType,
                                    options: _vm.approvalRoleList,
                                  },
                                  model: {
                                    value:
                                      item.initiatorParticipants.participants,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item.initiatorParticipants,
                                        "participants",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "item.initiatorParticipants.participants",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    item.participantType === "superiors"
                      ? [
                          _c("div", { staticClass: "line" }),
                          _c("h3", { staticClass: "yang" }, [
                            _vm._v("选择汇报上级"),
                          ]),
                          _c("ReCodeSelect", {
                            attrs: {
                              participantType: item.participantType,
                              options: _vm.reportSuperList,
                            },
                            model: {
                              value: item.participants,
                              callback: function ($$v) {
                                _vm.$set(item, "participants", $$v)
                              },
                              expression: "item.participants",
                            },
                          }),
                        ]
                      : _vm._e(),
                    item.participantType === "role"
                      ? [
                          _c("div", { staticClass: "line" }),
                          _c("h3", { staticClass: "yang" }, [
                            _vm._v("选择角色"),
                          ]),
                          _c("ReCodeSelect", {
                            attrs: {
                              participantType: item.participantType,
                              options: _vm.approvalRoleList,
                            },
                            model: {
                              value: item.participants,
                              callback: function ($$v) {
                                _vm.$set(item, "participants", $$v)
                              },
                              expression: "item.participants",
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            }
          ),
          _vm.canaddApproval
            ? [
                _c(
                  "div",
                  { staticClass: "approval-button" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link", prefix: "add-one" },
                        on: {
                          click: function ($event) {
                            return _vm.addApproval("approval")
                          },
                        },
                      },
                      [_vm._v("添加审批人")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          [
            _c("div", { staticClass: "approv-type" }, [
              _c("div", { staticClass: "approv" }, [_vm._v("审批设置")]),
              _c(
                "div",
                { staticClass: "approv-body" },
                [
                  _c("h3", { staticClass: "appro" }, [
                    _vm._v("多人审批时采用的审批方式"),
                  ]),
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.cacheData.assigneeConfig.approvalType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.cacheData.assigneeConfig,
                            "approvalType",
                            $$v
                          )
                        },
                        expression: "cacheData.assigneeConfig.approvalType",
                      },
                    },
                    [
                      _vm._l(_vm.approvalTypeList, function (key, index1) {
                        return [
                          _c(
                            "a-radio",
                            {
                              key: "approvalTypeItem" + index1,
                              staticClass: "approver-selects",
                              attrs: { value: key.value },
                            },
                            [_vm._v(_vm._s(key.label))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c("h3", { staticClass: "top-height" }, [
                    _vm._v("审批人为空时"),
                  ]),
                  _c(
                    "a-radio-group",
                    {
                      on: { change: _vm.vacantSkipChange },
                      model: {
                        value: _vm.cacheData.assigneeConfig.vacantSkip,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.cacheData.assigneeConfig,
                            "vacantSkip",
                            $$v
                          )
                        },
                        expression: "cacheData.assigneeConfig.vacantSkip",
                      },
                    },
                    [
                      _vm._l(_vm.vacantSkipList, function (key, index1) {
                        return [
                          _c(
                            "a-radio",
                            {
                              key: "approvalTypeItem" + index1,
                              staticClass: "approver-selectss",
                              attrs: { value: key.value },
                            },
                            [_vm._v(_vm._s(key.label))]
                          ),
                        ]
                      }),
                      _vm.cacheData.assigneeConfig.vacantSkip === "assignperson"
                        ? [
                            _c("h3", { staticClass: "top-height" }, [
                              _vm._v("审批候选人"),
                            ]),
                            _c("PerTreeSelect", {
                              attrs: {
                                perTreeValue:
                                  _vm.cacheData.assigneeConfig.vacantCandidateUser.map(
                                    function (PerTreeSelectItem) {
                                      return PerTreeSelectItem.participant
                                    }
                                  ),
                                placeholder: "请选择人员",
                                modeType: "multiple",
                                replaceFields: {
                                  name: "participantType",
                                  id: "participants",
                                },
                              },
                              on: {
                                getUserInfo: function ($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.PerTreeSelectFunc.apply(
                                    void 0,
                                    argsArray.concat(
                                      [
                                        _vm.cacheData.assigneeConfig
                                          .vacantCandidateUser,
                                      ],
                                      [_vm.cacheData.assigneeConfig.vacantSkip]
                                    )
                                  )
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("h3", { staticClass: "top-height" }, [
                    _vm._v("审批人与发起人同一人时"),
                  ]),
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.cacheData.assigneeConfig.conflictType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.cacheData.assigneeConfig,
                            "conflictType",
                            $$v
                          )
                        },
                        expression: "cacheData.assigneeConfig.conflictType",
                      },
                    },
                    [
                      _vm._l(_vm.conflictTypeList, function (key, index1) {
                        return [
                          _c(
                            "a-radio",
                            {
                              key: "approvalTypeItem" + index1,
                              staticClass: "approver-selections",
                              attrs: { value: key.value },
                            },
                            [_vm._v(_vm._s(key.label))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ],
          void 0,
          _vm._l(_vm.cacheData.ccConfig.ccParticipants, function (item, index) {
            return _c("a-card", { key: "copyParticipants" + index }, [
              _c(
                "div",
                {
                  staticClass: "cardHeader",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("span", [_vm._v("抄送人选择")]),
                  _c(
                    "svg",
                    {
                      staticClass: "iconpark-icon",
                      on: {
                        click: function ($event) {
                          return _vm.delItem(index, "copy")
                        },
                      },
                    },
                    [_c("use", { attrs: { href: "#delete" } })]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "approvalItem" },
                [
                  _c(
                    "a-radio-group",
                    {
                      on: {
                        change: function ($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          return _vm.participantTypeChange.apply(
                            void 0,
                            argsArray.concat([index], ["ccConfig"])
                          )
                        },
                      },
                      model: {
                        value: item.participantType,
                        callback: function ($$v) {
                          _vm.$set(item, "participantType", $$v)
                        },
                        expression: "item.participantType",
                      },
                    },
                    [
                      _vm._l(_vm.approvalList, function (key, index1) {
                        return [
                          _c(
                            "a-radio",
                            {
                              key: "copyParticipantsItem" + index1,
                              staticClass: "approver-selection",
                              attrs: {
                                value: key.value,
                                disabled:
                                  _vm.copyHasInitiatorselect &&
                                  key.value === "initiatorselect",
                              },
                            },
                            [_vm._v(_vm._s(key.label))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  item.participantType === "depthead"
                    ? [
                        _c("div", { staticClass: "line" }),
                        _c("h3", { staticClass: "yang" }, [
                          _vm._v("选择部门负责人"),
                        ]),
                        _c("ReCodeSelect", {
                          attrs: {
                            participantType: item.participantType,
                            options: _vm.deptheadList,
                          },
                          model: {
                            value: item.participants,
                            callback: function ($$v) {
                              _vm.$set(item, "participants", $$v)
                            },
                            expression: "item.participants",
                          },
                        }),
                      ]
                    : _vm._e(),
                  item.participantType === "assignperson"
                    ? [
                        _c("div", { staticClass: "line" }),
                        _c("h3", { staticClass: "yang" }, [_vm._v("添加成员")]),
                        _c("PerTreeSelect", {
                          attrs: {
                            perTreeValue: item.participants.map(function (
                              PerTreeSelectItem
                            ) {
                              return PerTreeSelectItem.participant
                            }),
                            placeholder: "请选择人员",
                            modeType: "multiple",
                            replaceFields: {
                              name: "participantType",
                              id: "participants",
                            },
                          },
                          on: {
                            getUserInfo: function ($event) {
                              var i = arguments.length,
                                argsArray = Array(i)
                              while (i--) argsArray[i] = arguments[i]
                              return _vm.PerTreeSelectFunc.apply(
                                void 0,
                                argsArray.concat(
                                  [item.participants],
                                  [item.participantType]
                                )
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  item.participantType === "initiatorselect" &&
                  item.initiatorParticipants
                    ? [
                        _c("div", { staticClass: "line" }),
                        _c("h3", { staticClass: "yang" }, [_vm._v("选择方式")]),
                        _c(
                          "a-radio-group",
                          {
                            model: {
                              value: item.initiatorSelectType,
                              callback: function ($$v) {
                                _vm.$set(item, "initiatorSelectType", $$v)
                              },
                              expression: "item.initiatorSelectType",
                            },
                          },
                          _vm._l(
                            _vm.initiatorSelectType,
                            function (
                              initiatorParticipantsItem,
                              initiatorParticipantsIndex
                            ) {
                              return _c(
                                "a-radio",
                                {
                                  key:
                                    "copyInitiatorParticipantsItem" +
                                    initiatorParticipantsIndex,
                                  attrs: {
                                    value: initiatorParticipantsItem.value,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(initiatorParticipantsItem.label)
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        _c("div", { staticClass: "line" }),
                        _c("h3", { staticClass: "yang" }, [_vm._v("可选范围")]),
                        _c(
                          "a-radio-group",
                          {
                            model: {
                              value: item.initiatorParticipants.participantType,
                              callback: function ($$v) {
                                _vm.$set(
                                  item.initiatorParticipants,
                                  "participantType",
                                  $$v
                                )
                              },
                              expression:
                                "item.initiatorParticipants.participantType",
                            },
                          },
                          _vm._l(
                            _vm.initiatorParticipantsRangeList,
                            function (
                              initiatorParticipantsRangeItem,
                              initiatorParticipantsRangeIndex
                            ) {
                              return _c(
                                "a-radio",
                                {
                                  key:
                                    "copyInitiatorParticipantsRangeItem" +
                                    initiatorParticipantsRangeIndex,
                                  attrs: {
                                    value: initiatorParticipantsRangeItem.value,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(initiatorParticipantsRangeItem.label)
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        item.initiatorParticipants.participantType ===
                        "assignperson"
                          ? [
                              _c("div", { staticClass: "line" }),
                              _c("h3", { staticClass: "yang" }, [
                                _vm._v("添加成员"),
                              ]),
                              _c("UserSelect", {
                                attrs: {
                                  echoData:
                                    item.initiatorParticipants.participants,
                                },
                                on: {
                                  change: function ($event) {
                                    var i = arguments.length,
                                      argsArray = Array(i)
                                    while (i--) argsArray[i] = arguments[i]
                                    return _vm.perModelConfirm.apply(
                                      void 0,
                                      argsArray.concat(
                                        [
                                          item.initiatorParticipants
                                            .participants,
                                        ],
                                        [
                                          item.initiatorParticipants
                                            .participantType,
                                        ]
                                      )
                                    )
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                        item.initiatorParticipants.participantType === "role"
                          ? [
                              _c("div", { staticClass: "line" }),
                              _c("h3", { staticClass: "yang" }, [
                                _vm._v("添加角色"),
                              ]),
                              _c("ReCodeSelect", {
                                attrs: {
                                  participantType:
                                    item.initiatorParticipants.participantType,
                                  options: _vm.approvalRoleList,
                                },
                                model: {
                                  value:
                                    item.initiatorParticipants.participants,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.initiatorParticipants,
                                      "participants",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.initiatorParticipants.participants",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  item.participantType === "superiors"
                    ? [
                        _c("div", { staticClass: "line" }),
                        _c("h3", { staticClass: "yang" }, [
                          _vm._v("选择汇报上级"),
                        ]),
                        _c("ReCodeSelect", {
                          attrs: {
                            participantType: item.participantType,
                            options: _vm.reportSuperList,
                          },
                          model: {
                            value: item.participants,
                            callback: function ($$v) {
                              _vm.$set(item, "participants", $$v)
                            },
                            expression: "item.participants",
                          },
                        }),
                      ]
                    : _vm._e(),
                  item.participantType === "role"
                    ? [
                        _c("div", { staticClass: "line" }),
                        _c("h3", { staticClass: "yang" }, [_vm._v("选择角色")]),
                        _c("ReCodeSelect", {
                          attrs: {
                            participantType: item.participantType,
                            options: _vm.approvalRoleList,
                          },
                          model: {
                            value: item.participants,
                            callback: function ($$v) {
                              _vm.$set(item, "participants", $$v)
                            },
                            expression: "item.participants",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ])
          }),
          _c(
            "div",
            { staticClass: "approval-button" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "link", prefix: "add-one" },
                  on: {
                    click: function ($event) {
                      return _vm.addApproval("copy")
                    },
                  },
                },
                [_vm._v("添加抄送人")]
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }