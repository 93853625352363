var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ApprovalCategory" },
    [
      _c(
        "div",
        { staticClass: "category-header" },
        [
          _c("HeaderBack", {
            attrs: { title: "审批分类管理" },
            on: {
              back: function ($event) {
                return _vm.$emit("back")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "appForm",
        { on: { getSearchCallback: _vm.getSearchCallback } },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addCategory },
                },
                [_vm._v(" 新建分类 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          id: "mytb",
          columns: _vm.columns,
          "table-ref": "#mytb",
          "data-source": _vm.groupList,
          "row-key": "flowGroupId",
          pagination: false,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "div",
                  { staticClass: "operation-btn-box" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.editCategory(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-align-center" },
                      [
                        _c(
                          "a-popover",
                          { attrs: { placement: "bottom", trigger: "hover" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                { staticClass: "more-handle-btn" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "delete",
                                      attrs: { type: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deletCategory(scope.record)
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.move(scope.record)
                                        },
                                      },
                                    },
                                    [_vm._v(" 移动到新分组 ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "more-handle" }, [
                              _vm._v("···"),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "HandleModal",
        _vm._g(
          { ref: "handleModal", attrs: { "sort-num": _vm.groupList.length } },
          _vm.$listeners
        )
      ),
      _c("MoveModal", {
        ref: "moveModal",
        attrs: { "group-list": _vm.groupList },
        on: {
          submit: function ($event) {
            return _vm.$emit("upFlowGroup")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }