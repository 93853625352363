var render, staticRenderFns
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=60bc105c&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bc105c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/HRSAAS-WEB-DBSJ-DEV/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60bc105c')) {
      api.createRecord('60bc105c', component.options)
    } else {
      api.reload('60bc105c', component.options)
    }
    
  }
}
component.options.__file = "src/components/workflow/Process/index.vue"
export default component.exports