var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LogicFlow" },
    [
      _vm.formVisible
        ? [
            _c(
              "div",
              { staticClass: "logicMain" },
              [
                _c(
                  "a-tabs",
                  {
                    attrs: { "default-active-key": _vm.defaultActiveKey },
                    on: { change: _vm.callback },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tabBarExtraContent",
                          fn: function () {
                            return [
                              _c(
                                "a-button",
                                {
                                  staticClass: "editBtn",
                                  attrs: { icon: "edit" },
                                  on: {
                                    click: function ($event) {
                                      _vm.categoryBlock = true
                                    },
                                  },
                                },
                                [_vm._v(" 审批分类管理 ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3722850594
                    ),
                  },
                  [
                    _vm._l(_vm.tabList, function (key, index) {
                      return [
                        _c("a-tab-pane", {
                          key: index,
                          attrs: { tab: key.groupName },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
                _vm.tabList.length > 0
                  ? _c(
                      "appForm",
                      { attrs: { "form-list": _vm.formList } },
                      [
                        _c(
                          "template",
                          { slot: "customButton" },
                          [
                            _c(
                              "a-space",
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      prefix: "file-addition-one",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addprocess(false)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.fucName) + " ")]
                                ),
                                _vm.env === "development"
                                  ? _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          prefix: "file-addition-one",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addprocess(true)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.fucName) +
                                            "【内置流程】 "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _c("custom-table", {
                  attrs: {
                    columns: _vm.columns,
                    "data-source": _vm.dataSource,
                    "table-ref": ".logicMain",
                    "row-key": "flowId",
                    pagination: false,
                    loading: _vm.loading,
                  },
                  on: { change: _vm.loadDataList },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "processName",
                        fn: function (ref) {
                          var scope = ref.scope
                          return _c("span", {}, [
                            _c("div", [
                              _c("img", {
                                staticClass: "flowIcon",
                                attrs: {
                                  src:
                                    _vm.iconBasePath +
                                    "icons/" +
                                    scope.record.icon +
                                    ".png",
                                  alt: "",
                                  srcset: "",
                                },
                              }),
                              _vm._v(" " + _vm._s(scope.record.flowName) + " "),
                            ]),
                          ])
                        },
                      },
                      {
                        key: "switchStatus",
                        fn: function (ref) {
                          var scope = ref.scope
                          return _c("span", {}, [
                            _c(
                              "div",
                              [
                                _c("a-switch", {
                                  attrs: {
                                    size: "small",
                                    "default-checked": Boolean(
                                      scope.record.status
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var i = arguments.length,
                                        argsArray = Array(i)
                                      while (i--) argsArray[i] = arguments[i]
                                      return _vm.onChange.apply(
                                        void 0,
                                        argsArray.concat([scope])
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        },
                      },
                      {
                        key: "operation",
                        fn: function (ref) {
                          var scope = ref.scope
                          return [
                            _c(
                              "div",
                              { staticClass: "operation-btn-box" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "link" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(scope.record)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                ),
                                !scope.record.predefine
                                  ? _c(
                                      "a-popover",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "content" }, [
                                          _c(
                                            "div",
                                            { staticClass: "more-handle-btn" },
                                            [
                                              !scope.record.predefine
                                                ? _c(
                                                    "a-button",
                                                    {
                                                      attrs: { type: "link" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copy(
                                                            scope.record
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 复制 ")]
                                                  )
                                                : _vm._e(),
                                              !scope.record.predefine
                                                ? _c(
                                                    "a-button",
                                                    {
                                                      staticClass: "delete",
                                                      attrs: {
                                                        type: "link",
                                                        danger: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delet(
                                                            scope.record
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 删除 ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "more-handle" },
                                          [_vm._v("···")]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    803286770
                  ),
                }),
              ],
              1
            ),
          ]
        : _c(
            "div",
            { staticClass: "processDesign" },
            [
              _c("Designer", {
                ref: "designer",
                attrs: {
                  "group-list": _vm.tabList,
                  "active-flow-group-id": _vm.activeFlowGroupId,
                  "echo-data": _vm.echoData,
                  "flow-id": _vm.flowId,
                },
                on: { refresh: _vm.refresh, backForm: _vm.backForm },
              }),
            ],
            1
          ),
      _vm.categoryBlock
        ? _c(
            "div",
            { staticClass: "categoryBlock" },
            [
              _c("AppCategory", {
                attrs: { "group-list": _vm.tabList },
                on: {
                  back: function ($event) {
                    _vm.categoryBlock = false
                    _vm.init()
                  },
                  upFlowGroup: _vm.upFlowGroup,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }