<template>
  <Drawer
    v-if="properties"
    :size="'small'"
    :z-index="2"
    class="drawer"
    :title="properties.title"
    :visible.sync="visible"
    :closable="false"
    style="text-align: left"
    @close="cancel"
    @onSubmit="confirm"
  >
    <div class="content">
      <!-- 条件  -->
      <conditionProp
        v-if="value && isConditionNode()"
        ref="condition"
        :formData="formData"
        :value="value"
      />

      <!-- 审批人 -->
      <approval
        v-if="value && isApproverNode()"
        ref="approval"
        :value="value"
      />
    </div>
  </Drawer>
</template>
<script>
import { NodeUtils } from '../FlowCard/util.js'
import number from '@/components/CbNumber'
import Drawer from '@/components/CbDrawer/index'
import conditionProp from './condition'
import approval from './approval'
const defaultApproverForm = {
  approvers: [], // 审批人集合
  approverRoles: [], // 审批角色集合
  assigneeType: 'user', // 指定审批人
  formOperates: [], // 表单操作权限集合
  optionalMultiUser: 'orSign', // 会签 counterSign,或签名 orSign
  expression: '',
  rejectConfig: '0' // 驳回设置
}
export default {
  name: 'PropPanel',
  components: {
    number,
    conditionProp,
    approval,
    Drawer
  },
  props: ['value', /* 整个节点数据*/ 'processData', /* formData */ 'formData'],
  data() {
    return {
      // 缓存一个备份儿数据，保存的时候再合并到当前数据
      cacheData: null,
      visible: false, // 控制面板显隐
      globalFormOperate: null, // 统一设置节点表单权限
      activeName: 'config', // or formAuth  Tab面板key
      showingPCons: [], // 用户选择了得条件  被选中的才会被展示在面板上编辑
      pconditions: [], // 从vuex中获取的可以作为流程图条件的集合
      conditionLabel: '', // 条件显示的内容
      condition: null, // 条件选择
      dialogVisible: false, // 控制流程条件选项Dialog显隐
      // 当前节点数据
      properties: {},
      // 发起人  start节点和condition节点需要
      initiator: {},
      sortNumLength: 0, // 当为条件节点时  显示节点优先级选项的数据
      startForm: {
        formOperates: []
      },
      approverForm: JSON.parse(JSON.stringify(defaultApproverForm)),
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.approverForm = JSON.parse(JSON.stringify(defaultApproverForm)) // 重置数据为默认状态
        return
      }
      this.isStartNode() && this.initStartNodeData()
      this.isApproverNode() && this.initApproverNodeData()
      this.isConditionNode() && this.initConditionNodeData()
    },
    value(newVal) {
      if (newVal) {
        this.visible = true
        this.properties = JSON.parse(JSON.stringify(newVal))
        // if (this.properties) {
        //   NodeUtils.isConditionNode(newVal) && this.getsortNumLength()
        // }
      }
    }
  },
  methods: {
    initFormOperates(target) {
      const formOperates = (target.properties && target.properties.formOperates) || []
      // 自定义组件不加入权限控制
      const res = []
      const defaultRead = true
      const defaultWrite = false
      // const getReadPermissionById = id => {
      //   const permission = formOperates.find(t => t.formId === id)
      //   return permission !== undefined ? permission.read : defaultRead
      // }
      // const getWritePermissionById = id => {
      //   const permission = formOperates.find(t => t.formId === id)
      //   return permission !== undefined ? permission.write : defaultWrite
      // }
      // const format = (list, parentName = '') =>
      //   list.map(t => {
      //     const data = {
      //       formId: t.formId,
      //       required: t.required,
      //       vModel: t.vModel,
      //       label: parentName ? [parentName, t.label].join('.') : t.label,
      //       read: getReadPermissionById(t.formId),
      //       write: getWritePermissionById(t.formId)
      //     }
      //     res.push(data)
      //     Array.isArray(t.children) && format(t.children, t.label)
      //   })
      // const formItems = this.$store.state.designer.formItemList.filter(t => t.cmpType !== 'custom')
      // format(formItems)
      return res
    },

    initStartNodeData() {
      this.initInitiator()
      this.activeName = 'formAuth'
      this.startForm.formOperates = this.initFormOperates(this.value)
    },
    getOrgSelectLabel(type) {
      return this.$refs[type + '-org']['selectedLabels']
    },
    /**
         * 审批节点确认保存
         */
    approverNodeComfirm() {
      // this.approverForm.title = this.properties.title
      // const formOperates = this.approverForm.formOperates.map(t => ({ formId: t.formId, formOperate: t.formOperate }));
      // Object.assign(this.properties, this.approverForm, { formOperates });
      // Object.assign(this.properties, this.approverForm)
      this.$emit('confirm', this.properties, content || '请设置审批人')
      this.visible = false
    },
    // 确认修改
    confirm() {
      // 点击确定，吧当前备份儿数据替换上去
      // this.value = this.cacheData
      this.isClickConfirm = true
      this.isApproverNode() && this.$refs.approval.approverNodeComfirm(this.emitFunc)
      this.isConditionNode() && this.$refs.condition.conditionNodeComfirm(this.emitFunc)
    },
    emitFunc(cacheData) {
      this.$emit('confirm', cacheData, this.isConditionNode())
      this.visible = false
    },
    // 关闭抽屉
    cancel() {
      setTimeout(() => {
        this.$emit('cancel')
        this.visible = false
      }, 0)
    },
    /**
         * 删除流程条件
         */
    onDelCondition(condition) {
      this.pconditions.splice(index, 1)
    },
    // 配合getsortNumLength 获取前一个节点条件数组长度 用于设置优先级
    getPrevData() {
      return NodeUtils.getPreviousNode(this.value.prevId, this.processData)
    },
    // 用于获取节点优先级范围
    getsortNumLength() {
      this.sortNumLength = this.getPrevData().conditionNodes.length
    },
    // 判断是否是条件节点
    isConditionNode() {
      return this.value ? NodeUtils.isConditionNode(this.value) : false
    },
    /** 判断是否是审批节点*/
    isApproverNode() {
      return this.value ? NodeUtils.isApproverNode(this.value) : false
    },

    isStartNode() {
      return this.value ? NodeUtils.isStartNode(this.value) : false
    },

    initInitiator() {
      const initiator = this.value.properties && this.value.properties.initiator
    },
    /**
         * 初始化审批节点所需数据
         */
    initApproverNodeData() {
      Object.assign(this.approverForm, this.value.properties)
      this.approverForm.formOperates = this.initFormOperates(this.value)
      this.activeName = 'config'
    },
    /**
         * 初始化条件节点数据
         */
    initConditionNodeData() {
      // 初始化条件表单数据
      this.condition = this.value.properties && this.value.properties.condition
      this.conditionLabel = this.value.properties && this.value.properties.conditionLabel
      this.activeName = 'config'
    }
  }
}
</script>
<style lang="stylus">
.condition-dialog {
  .el-dialog__header{
    border-bottom : 1px solid #eee;
  }
}
</style>
<style lang="stylus" scoped>
.content{
  margin-bottom 44px;
}
.actions {
  height: 60px;
  position: absolute;
  bottom: 0;
  right : 0;
  padding: 6px 26px;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.radio-item {
  width: 110px;
  padding: 6px;
}

.sortNum-select {
  width: 118px;
  position: absolute;
  right: 26px;
}

.form-auth-table{
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  .auth-table-header{
    background: #fafafa
    line-height: 40px;
  }
  .row{
    display: flex;
    align-items: center;
    line-height: 32px;
    padding: 8px 12px;
    border-bottom: 1px solid #efefef;
    &:hover{
      background: #f5f7fa;
    }
    .label{
      flex:1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .required{
        color: #f2725e;
      }
    }
    .radio-group{
      flex: 2;
      display: flex;
      justify-content: space-between;
    }
  }
}

.approver-pane{
  // overflow-x: hidden;
  width: 100%;
  height: 100%;
  .option-box {
    font-size 14px
    padding-left 1rem
  }
}
</style>
