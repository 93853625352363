var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.properties
    ? _c(
        "Drawer",
        {
          staticClass: "drawer",
          staticStyle: { "text-align": "left" },
          attrs: {
            size: "small",
            "z-index": 2,
            title: _vm.properties.title,
            visible: _vm.visible,
            closable: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.cancel,
            onSubmit: _vm.confirm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm.value && _vm.isConditionNode()
                ? _c("conditionProp", {
                    ref: "condition",
                    attrs: { formData: _vm.formData, value: _vm.value },
                  })
                : _vm._e(),
              _vm.value && _vm.isApproverNode()
                ? _c("approval", {
                    ref: "approval",
                    attrs: { value: _vm.value },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }