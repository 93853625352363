var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        "z-index": 100,
        closable: true,
        visible: _vm.moveVisble,
        size: "normal",
        "mask-closable": false,
        title: "移动到新分类",
        "ok-text": "确认",
      },
      on: { ok: _vm.handleSubmit, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "将当前分组内的所有表单移动到新分组",
                prop: "targetFlowGroupId",
              },
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    placeholder:
                      _vm.CurrentGroup.currentGroupName + "(当前分组)",
                  },
                  model: {
                    value: _vm.form.targetFlowGroupId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "targetFlowGroupId", $$v)
                    },
                    expression: "form.targetFlowGroupId",
                  },
                },
                _vm._l(_vm.filterGroupList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.flowGroupId,
                      attrs: { value: item.flowGroupId },
                    },
                    [_vm._v(_vm._s(item.groupName))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }