var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Jcommon-layout" }, [
    _c("div", { staticClass: "page Jcommon-layout-center" }, [
      _c("header", { staticClass: "page__header" }, [
        _c(
          "div",
          { staticClass: "page-actions" },
          [
            _c("a-button", {
              attrs: { type: "iconBtn", icon: "left" },
              on: { click: _vm.exit },
            }),
            _c("div", [_vm._v(_vm._s(_vm.title))]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "step-tab" },
          [
            _vm._l(_vm.steps, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "step",
                  class: [
                    _vm.activeStep == item.key
                      ? "active"
                      : _vm.activeIndex > index
                      ? "normal"
                      : "default",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.changeSteps(item, index)
                    },
                  },
                },
                [
                  index !== 0
                    ? _c("span", { staticClass: "step-line" })
                    : _vm._e(),
                  _c("span", { staticClass: "step-index" }, [
                    _vm._v(_vm._s(index + 1)),
                  ]),
                  _vm._v(" " + _vm._s(item.label) + " "),
                ]
              )
            }),
            _c("div", {
              staticClass: "ghost-step step",
              style: { transform: _vm.translateX },
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "designer-actions" },
          [
            _vm.errList.length > 0
              ? _c(
                  "a-popover",
                  { attrs: { placement: "bottom", trigger: "click" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "err-content",
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("内容不完善"),
                        ]),
                        _c("div", { staticClass: "description" }, [
                          _vm._v("以下内容不完善，请修改后发布"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "err-list" },
                          [
                            _vm._l(_vm.errList, function (key, index) {
                              return _c(
                                "div",
                                {
                                  key: "errItem" + index,
                                  staticClass: "err-item",
                                },
                                [
                                  _c("span", { staticClass: "module-name" }, [
                                    _vm._v(_vm._s(key.title)),
                                  ]),
                                  _c("span", { staticClass: "msg" }, [
                                    _vm._v(_vm._s(key.msg)),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "put",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toFixErr(key.stepName)
                                        },
                                      },
                                    },
                                    [_vm._v("去修改")]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "err-tip" }, [_vm._v("未完善")]),
                  ]
                )
              : _vm._e(),
            _vm.env === "development"
              ? _c(
                  "a-button",
                  { staticClass: "a-but", on: { click: _vm.changeDesignType } },
                  [_vm._v("切换表单")]
                )
              : _vm._e(),
            _c("a-button", { staticClass: "a-but" }, [_vm._v("预览")]),
            _c(
              "a-button",
              {
                staticClass: "a-btn",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.sendToServer()
                  },
                },
              },
              [_vm._v("发布")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "section",
        { staticClass: "Jcommon-layout-main Jflex-main" },
        [
          _c("BasicSetting", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeStep === "basicSetting",
                expression: "activeStep === 'basicSetting'",
              },
            ],
            ref: "basicSetting",
            attrs: {
              conf: _vm.echoData ? _vm.echoData.basicSetting : {},
              "tab-name": "basicSetting",
              "group-list": _vm.groupList,
              "active-flow-group-id": _vm.activeFlowGroupId,
            },
          }),
          _c("KFormDesign", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeStep === "formDesign",
                expression: "activeStep === 'formDesign'",
              },
            ],
            ref: "formDesign",
            attrs: {
              "process-data": _vm.params.flowDesign,
              "flow-setting": _vm.params.flowSetting,
              "tab-name": "formDesign",
              type: _vm.formType,
              toolbars: _vm.KFormToolbar,
              "custom-filter-type": ["selectIcon", "threeLevel"],
            },
          }),
          _c("Process", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeStep === "processDesign",
                expression: "activeStep === 'processDesign'",
              },
            ],
            ref: "processDesign",
            attrs: {
              conf: _vm.echoData ? _vm.echoData.flowDesign : {},
              "form-data": _vm.params.formDesign.list,
              "tab-name": "processDesign",
            },
          }),
          _c("AdSetting", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeStep === "AdSetting",
                expression: "activeStep === 'AdSetting'",
              },
            ],
            ref: "AdSetting",
            attrs: { conf: _vm.echoData ? _vm.echoData.adSetting : {} },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }