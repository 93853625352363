<template>
  <div class="setting-container">
    <a-form
      ref="Form"
      :form="formData"
      label-width="100px"
      label-position="top"
    >
      <a-form-item label="审批人去重设置" prop="approveDuplicate">
        <a-radio-group
          v-model="formData.approveDuplicate"
        >
          <template v-for="(key, index) in noRepeat">
            <a-radio class="radioItem" :key="`noRepeat${index}`" :value="key.value">{{ key.label }}</a-radio>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="抄送设置" prop="approveCopy">
        <a-radio-group v-model="formData.approveCopy">
          <template v-for="(key, index) in copy">
            <a-radio class="radioItem" :key="`noRepeat${index}`" :value="key.value">{{ key.label }}</a-radio>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="审批意见" prop="opinionRequired">
        <a-radio-group v-model="formData.opinionRequired">
          <template v-for="(key, index) in approvalMsg">
            <a-radio class="radioItem" :key="`noRepeat${index}`" :value="key.value">{{ key.label }}</a-radio>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="手写签名" prop="signRequired">
        <a-checkbox v-model="signRequired" >处理审批时必须填写手写签名</a-checkbox>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['conf'],
  data() {
    return {
      form: null,
      signRequired: false,
      formData: {
        approveDuplicate: 'duplicateskip', // 审批人去重
        approveCopy: 'notifycopypass', // 抄送设置
        opinionRequired: 'notpassrequired', // 审批意见填写
        cancelApproved: '1' // 撤销
      },
      rules: {
        approveDuplicate: [
          { required: true, message: '请选择审批人去重方式', trigger: 'blur' }
        ],
        approveCopy: [
          { required: true, message: '请选择抄送设置', trigger: 'blur' }
        ],
        opinionRequired: [
          { required: true, message: '请选择审批意见填写方式', trigger: 'blur' }
        ],
        cancelApproved: [
          { required: true, message: '请选择撤销方式', trigger: 'blur' }
        ]
      },
      noRepeat: [
        {
          label: '对同一审批人在流程中出现多次去重',
          value: 'duplicateskip'
        },
        {
          label: '对同一审批人在流程中连续出现去重',
          value: 'continuousskip'
        },
        {
          label: '不去重，每个节点都需要审批',
          value: 'noskip'
        }
      ],
      copy: [
        {
          label: '审批发起时通知所有抄送人',
          value: 'notifycopystart'
        },
        {
          label: '审批全部通过通知所有抄送人',
          value: 'notifycopypass'
        },
        {
          label: '审批过程中通知所有抄送人',
          value: 'notifycopycourse'
        },
      ],
      approvalMsg: [
        {
          label: '审批意见必填',
          value: 'required'
        },
        {
          label: '审批拒绝时必填',
          value: 'notpassrequired'
        }
      ],

    }
  },
  created() {
    if (typeof this.conf === 'object' && this.conf !== null) {
      this.signRequired = Boolean(this.conf.signRequired)
      Object.assign(this.formData, this.conf)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.form = this.$form.createForm(this)
    })
  },
  methods: {
    getErrList() {
      return new Promise((resolve, reject) => {
        this.form.validateFields((err, values) => {
          const errList = []
          if (err) {
            Object.values(err).forEach(item => {
              item.errors.forEach(key => {
                errList.push(key)
              })
            })
          }
          resolve(errList)
        })
      })
    },
    async getData() {
      return new Promise((resolve, reject) => {
        this.form.validateFields((err) => {
          if (!err) {
            this.formData['signRequired'] = this.signRequired ? 1 : 0
            resolve(this.formData)
          }
          resolve({})
        })
      })
    }
  }
}

</script>
<style lang="less" scoped>
.setting-container{
  width: 744px;
  height: 100%;
  margin: 0 auto;
  background: #fff;
  padding: 40px 100px 0;
  border-left: 2px solid @sc-greyBg-20;
  border-right: 2px solid @sc-greyBg-20;
  z-index: 1000;
}
.radioItem{
  display: block;
  line-height: 34px;
}
</style>

