export default {
  start: {
    type: 'start',
    content: '所有人',
    title: '发起'
  },
  approver: {
    type: 'approval',
    content: '请设置审批人',
    // properties: { initiator: null, description: [] },
    title: '审批',
    assigneeConfig: {
      // 审批方式
      approvalType: '',
      // 审批人为空时操作： 自动通过 指定人员审批 终止审批流
      vacantSkip: '',
      // 审批人空缺候选人列表
      vacantCandidateUser: [],
      // 审批人发起人同一个人时候
      conflictType: '',
      // 审批人配置数据
      assigneeParticipants: [
        {
          participantType: '',
          // initiatorSelectType: '',
          // initiatorParticipants: {
          //   participantType: '',
          //   participants: []
          // },
          participants: []
        }
      ]
    },
    ccConfig: {
      ccParticipants: []
    }
  },
  condition: {
    type: 'condition',
    content: '请设置条件',
    conditionGroups: [
      {
        "details": [
          {
            type: undefined,
            conditionType: undefined,
            conditionName: undefined,
            referName: undefined,
            content: undefined,
            contentType: undefined,
            contentName: undefined,
            element: undefined,
            conditionRange: undefined,
            matchRule: undefined,
            mathOrRangeName: undefined,
            sortNum: 0
          }
        ]
      }
    ],
    title: '条件'
  },
  branch: { type: 'branch', content: '', properties: {}},
  empty: { type: 'empty', content: '', properties: {}}
}
