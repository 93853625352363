import nodeConfig from './config.js'
const isEmpty = data => data === null || data === undefined || data === ''
const isEmptyArray = data => (Array.isArray(data) ? data.length === 0 : true)

export class NodeUtils {
  /**
     * 根据自增数生成64进制id
     * @returns 64进制id字符串
     */
  static idGenerator() {
    let qutient = new Date() - new Date('2020-08-01')
    qutient += Math.ceil(Math.random() * 1000) // 防止重複
    const chars = 'ABCDEFGHIGKLMNOPQRSTUVWXYZabcdefghigklmnopqrstuvwxyz'
    const charArr = chars.split('')
    const radix = chars.length
    const res = []
    do {
      const mod = qutient % radix
      qutient = (qutient - mod) / radix
      res.push(charArr[mod])
    } while (qutient)
    return res.join('')
  }

  /**
     * 判断节点类型
     * @param {Node} node - 节点数据
     * @returns Boolean
     */
  static isConditionNode(node) {
    return node && node.type === 'condition'
  }
  static isCopyNode(node) {
    return node && node.type === 'copy'
  }
  static isStartNode(node) {
    return node && node.type === 'start'
  }
  static isApproverNode(node) {
    return node && node.type === 'approval'
  }
  /**
     * 创建指定节点
     * @param { String } type - 节点类型
     * @param { Object } previousNodeId - 父节点id
     * @returns { Object } 节点数据
     */
  static createNode(type, previousNodeId) {
    const res = JSON.parse(JSON.stringify(nodeConfig[type]))
    res.id = this.idGenerator()
    res.prevId = previousNodeId
    return res
  }
  /**
     * 获取指定节点的父节点（前一个节点）
     * @param { String } prevId - 父节点id
     * @param { Object } processData - 流程图全部数据
     * @returns { Object } 父节点
     */
  static getPreviousNode(prevId, processData) {
    if (processData.id === prevId) return processData
    if (processData.children) {
      const r1 = this.getPreviousNode(prevId, processData.children)
      if (r1) {
        return r1
      }
    }
    if (processData.conditionNodes) {
      for (const c of processData.conditionNodes) {
        const r2 = this.getPreviousNode(prevId, c)
        if (r2) {
          return r2
        }
      }
    }
  }

  /**
     * 给固定节点赋值，把当前操作面板的属性叠加到processData中
     * @param { String } prevId - 父节点id
     * @param { Object } processData - 流程图全部数据
     */
    static dataExtendToProcess(cnode, assignData ,processData) {
      let prevNode = this.getPreviousNode(cnode.prevId, processData);
      prevNode.conditionNodes[cnode.sortNum] = assignData
    }

  /**
     * 删除节点
     * @param { Object  } nodeData - 被删除节点的数据
     * @param { Object  } processData - 流程图的所有节点数据
     */
  static deleteNode(nodeData, processData, checkEmpty = true) {
    const prevNode = this.getPreviousNode(nodeData.prevId, processData)
    if (checkEmpty && prevNode.type === 'empty') {
      if (this.isConditionNode(nodeData)) {
        const willDelBranch = prevNode.conditionNodes.length === 2
        const target = willDelBranch ? prevNode : nodeData
        this.deleteNode(target, processData, willDelBranch)
      } else {
        if (isEmptyArray(prevNode.conditionNodes)) {
          this.deleteNode(prevNode, processData)
        }
        this.deleteNode(nodeData, processData, false)
      }
      // this.deleteNode( prevNode, processData )
      // !this.isConditionNode(nodeData) && this.deleteNode(nodeData, processData)
      return
    }

    const concatChild = (prev, delNode) => {
      prev.children = delNode.children
      isEmptyArray(prev.conditionNodes) && (prev.conditionNodes = delNode.conditionNodes)
      prev.children && (prev.children.prevId = prev.id)
      prev.conditionNodes && prev.conditionNodes.forEach(c => (c.prevId = prev.id))
    }
    if (this.isConditionNode(nodeData)) {
      const cons = prevNode.conditionNodes
      const index = cons.findIndex(c => c.id === nodeData.id)
      if (cons.length > 2) {
        cons.splice(index, 1)
      } else {
        const anotherCon = cons[+!index]
        delete prevNode.conditionNodes
        if (prevNode.children) {
          let endNode = anotherCon
          while (endNode.children) {
            endNode = endNode.children
          }
          endNode.children = prevNode.children
          endNode.children.prevId = endNode.id
        }
        concatChild(prevNode, anotherCon)
        if (prevNode.children && prevNode.children.type === 'empty') {
          this.deleteNode(prevNode.children, prevNode)
        }
      }
      // 重新编排优先级
      cons.forEach((c, i) => (c.sortNum = i))
      return
    }
    concatChild(prevNode, nodeData)
  }
  // TODO:
  // static copyNode ( nodeData, processData ) {
  //   let prevNode = this.getPreviousNode( nodeData.prevId, processData )
  //   let index = prevNode.conditionNodes.findIndex( c => c.id === nodeData.id )

  // }
  /**
     * 添加审计节点（普通节点 approver）
     * @param { Object } data - 目标节点数据，在该数据节点之后添加审计节点
     * @param { Object } isBranchAction - 目标节点数据，是否是条件分支
     * @param { Object } newChildNode - 传入的新的节点 用户操作均为空  删除操作/添加抄送人 会传入该参数 以模拟添加节点
     */
  static addApprovalNode(data, isBranchAction, newChildNode = undefined) {
    const oldChildNode = data.children
    newChildNode = newChildNode || this.createNode('approver', data.id)
    data.children = newChildNode
    if (oldChildNode) {
      newChildNode.children = oldChildNode
      oldChildNode.prevId = newChildNode.id
    }
    const conditionNodes = data.conditionNodes
    if (Array.isArray(conditionNodes) && !isBranchAction && conditionNodes.length) {
      newChildNode.conditionNodes = conditionNodes.map(c => {
        c.prevId = newChildNode.id
        return c
      })
      delete data.conditionNodes
    }
    if (oldChildNode && oldChildNode.type === 'empty' && newChildNode.type !== 'empty' && oldChildNode.conditionNodes.length === 0) {
      this.deleteNode(oldChildNode, data)
    }
  }
  /**
     * 添加空节点
     * @param { Object } data - 空节点的父级节点
     * @return { Object } emptyNode - 空节点数据
     */
  static addEmptyNode(data) {
    const emptyNode = this.createNode('empty', data.id)
    this.addApprovalNode(data, true, emptyNode)
    return emptyNode
  }

  static addCopyNode(data, isBranchAction) {
    // 复用addApprovalNode  因为抄送人和审批人基本一致
    this.addApprovalNode(data, isBranchAction, this.createNode('copy', data.id))
  }
  /**
     * 添加条件节点 condition 通过点击添加条件进入该操作
     * @param { Object } data - 目标节点所在分支数据，在该分支最后添加条件节点
     */
  static appendConditionNode(data) {
    const conditions = data.conditionNodes
    const node = this.createNode('condition', data.id)
    const defaultNodeIndex = conditions.findIndex(node => node.defaultCondition)
    node.sortNum = conditions.length
    if (defaultNodeIndex > -1) {
      conditions.splice(-1, 0, node) // 插在倒数第二个
      // 更新优先级
      node.sortNum = conditions.length - 2
      conditions[conditions.length - 1].sortNum = conditions.length - 1
    } else {
      conditions.push(node)
    }
    // 添加新的条件分支不再重置默认条件
    // this.setDefaultCondition(node, data)
  }
  /**
     * 添加条件分支 branch
     * @param { Object } data - 目标节点所在节点数据，在该节点最后添加分支节点
     */
  static appendBranch(data, isBottomBtnOfBranch) {
    // isBottomBtnOfBranch 用户点击的是分支树下面的按钮
    let nodeData = data
    // 由于conditionNodes是数组 不能添加下级分支 故在两个分支树之间添加一个不会显示的正常节点 兼容此种情况
    if (Array.isArray(data.conditionNodes) && data.conditionNodes.length) {
      if (isBottomBtnOfBranch) {
        // 添加一个模拟用的空白节点并返回这个节点，作为新分支的父节点
        nodeData = this.addEmptyNode(nodeData, true)
      } else {
        const emptyNode = this.addEmptyNode(nodeData, true)
        emptyNode.conditionNodes = nodeData.conditionNodes
        emptyNode.conditionNodes.forEach(n => {
          n.prevId = emptyNode.id
        })
      }
    }
    const conditionNodes = [this.createNode('condition', nodeData.id), this.createNode('condition', nodeData.id)].map((c, i) => {
      c.title += i + 1
      c.sortNum = i
      if (i === 1) {
        c.title = '默认条件'
        c['defaultCondition'] = 1
        delete c.conditionGroups
        c['content'] = '其他情况'
      }
      return c
    })
    nodeData.conditionNodes = conditionNodes
  }
  /**
     * 重设节点优先级（条件节点）
     * @param {Node} cnode - 当前节点
     * @param {Number} oldPriority - 替换前的优先级（在数组中的顺序）
     * @param {Node} processData - 整个流程图节点数据
     */
  static resortPrioByCNode(cnode, oldPriority, processData) {
    // 当前节点为默认节点 取消修改优先级
    if (cnode.properties.isDefault) {
      cnode.sortNum = oldPriority
      return
    }
    const prevNode = this.getPreviousNode(cnode.prevId, processData)
    const newPriority = cnode.sortNum
    // 替换节点为默认节点 取消修改优先级
    if (prevNode.conditionNodes[newPriority].properties.isDefault) {
      cnode.sortNum = oldPriority
      return
    }
    const delNode = prevNode.conditionNodes.splice(newPriority, 1, cnode)[0]
    delNode.sortNum = oldPriority
    prevNode.conditionNodes[oldPriority] = delNode
  }

  /**
     * 提升条件节点优先级——排序在前
     * @param { Object } data - 目标节点数据
     * @param { Object  } processData - 流程图的所有节点数据
     */
  static increasePriority(data, processData) {
    if (data.properties.isDefault) {
      // 默认节点不能修改优先级
      return
    }
    // 分支节点数据 包含该分支所有的条件节点
    const prevNode = this.getPreviousNode(data.prevId, processData)
    const branchData = prevNode.conditionNodes
    const index = branchData.findIndex(c => c === data)
    if (index) {
      // 和前一个数组项交换位置 Array.prototype.splice会返回包含被删除的项的集合（数组）
      branchData[index - 1].sortNum = index
      branchData[index].sortNum = index - 1
      branchData[index - 1] = branchData.splice(index, 1, branchData[index - 1])[0]
    }
  }
  /**
     * 降低条件节点优先级——排序在后
     * @param { Object } data - 目标节点数据
     * @param { Object  } processData - 流程图的所有节点数据
     */
  static decreasePriority(data, processData) {
    // 分支节点数据 包含该分支所有的条件节点
    const prevNode = this.getPreviousNode(data.prevId, processData)
    const branchData = prevNode.conditionNodes
    const index = branchData.findIndex(c => c.id === data.id)
    if (index < branchData.length - 1) {
      const lastNode = branchData[index + 1]
      if (lastNode.properties.isDefault) {
        // 默认节点不能修改优先级
        return
      }
      // 和后一个数组项交换位置 Array.prototype.splice会返回包含被删除的项的集合（数组）
      lastNode.sortNum = index
      branchData[index].sortNum = index + 1
      branchData[index + 1] = branchData.splice(index, 1, branchData[index + 1])[0]
    }
  }
  /**
     * 当有其他条件节点设置条件后 检查并设置最后一个节点为默认节点
     * @param {Node} cnode  - 当前节点
     * @param {Node} processData - 整个流程图节点数据或父级节点数据
     */
  static setDefaultCondition(cnode, processData) {
    const DEFAULT_TEXT = '其他情况进入此流程'
    const conditions = this.getPreviousNode(cnode.prevId, processData).conditionNodes
    const hasCondition = node => node.properties && !isEmptyArray(node.properties.conditions)
    const clearDefault = node => {
      node.properties.isDefault = false
      node.content === DEFAULT_TEXT && (node.content = '请设置条件')
    }
    const setDefault = node => {
      node.properties.isDefault = true
      node.content = DEFAULT_TEXT
    }
    let count = 0
    conditions.slice(0, -1).forEach(node => {
      hasCondition(node) && count++
      clearDefault(node)
    })
    const lastNode = conditions[conditions.length - 1]
    count > 0 && !hasCondition(lastNode) ? setDefault(lastNode) : clearDefault(lastNode)
  }
  /**
     * 校验单个节点必填项完整性
     * @param {Node} node - 节点数据
     */
  static checkNode(node, parent, msgList) {
    // 抄送人应该可以默认自选
    let valid = true
    const props = node.properties
    // 条件节点是否填充完整
    // debugger
    this.isConditionNode(node) && !node.defaultCondition && node.content === '请设置条件' && (valid = false)
    // 审批节点是否填充完整
    this.isApproverNode(node) && node.content === '请设置审批人' && (valid = false)
    if (!valid && msgList) {
      msgList.push({ message: node.content, type: node.type })
    }
    return valid
  }
  /**
     * 判断所有节点是否信息完整,不完整的收集报错信息
     * @param {Node} processData - 整个流程图数据
     * @returns {Boolean}
     */
  static checkAllNode(processData) {
    let valid = true
    let msgList = []
    // 是否存在审批人
    let hasApproval = false
    const loop = (node, callback, parent) => {
      !this.checkNode(node, parent, msgList) && callback()
      if (this.isApproverNode(node)) {
        console.log('xxxxxxxxxxxxxxx')
        hasApproval = true
      }
      if (node.children) loop(node.children, callback, parent)
      if (!isEmptyArray(node.conditionNodes)) {
        node.conditionNodes.forEach(n => loop(n, callback, node))
      }
    }
    loop(processData, () => (valid = false))
    if (!hasApproval) {
      msgList.push({
        message: '流程设计中必须存在审批节点',
        type: ''
      })
    }
    return {
      valid,
      msgList
    }
  }
}

/**
 * 添模拟数据
 */
export function getMockData() {
  const startNode = NodeUtils.createNode('start')
  // startNode. children = NodeUtils.createNode('approver', startNode.id)
  return startNode
}
