var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "a-form",
        {
          ref: "Form",
          attrs: {
            form: _vm.formData,
            "label-width": "100px",
            "label-position": "top",
          },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "审批人去重设置", prop: "approveDuplicate" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.formData.approveDuplicate,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "approveDuplicate", $$v)
                    },
                    expression: "formData.approveDuplicate",
                  },
                },
                [
                  _vm._l(_vm.noRepeat, function (key, index) {
                    return [
                      _c(
                        "a-radio",
                        {
                          key: "noRepeat" + index,
                          staticClass: "radioItem",
                          attrs: { value: key.value },
                        },
                        [_vm._v(_vm._s(key.label))]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "抄送设置", prop: "approveCopy" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.formData.approveCopy,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "approveCopy", $$v)
                    },
                    expression: "formData.approveCopy",
                  },
                },
                [
                  _vm._l(_vm.copy, function (key, index) {
                    return [
                      _c(
                        "a-radio",
                        {
                          key: "noRepeat" + index,
                          staticClass: "radioItem",
                          attrs: { value: key.value },
                        },
                        [_vm._v(_vm._s(key.label))]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "审批意见", prop: "opinionRequired" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.formData.opinionRequired,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "opinionRequired", $$v)
                    },
                    expression: "formData.opinionRequired",
                  },
                },
                [
                  _vm._l(_vm.approvalMsg, function (key, index) {
                    return [
                      _c(
                        "a-radio",
                        {
                          key: "noRepeat" + index,
                          staticClass: "radioItem",
                          attrs: { value: key.value },
                        },
                        [_vm._v(_vm._s(key.label))]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "手写签名", prop: "signRequired" } },
            [
              _c(
                "a-checkbox",
                {
                  model: {
                    value: _vm.signRequired,
                    callback: function ($$v) {
                      _vm.signRequired = $$v
                    },
                    expression: "signRequired",
                  },
                },
                [_vm._v("处理审批时必须填写手写签名")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }