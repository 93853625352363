<template>
  <div class="setting-container">
    <k-form-build ref="KFB" class="form" :value="jsonData" @change="change" />
    <a-form v-if="showSelectUser" class="form" labelLayout="flex" layout="horizontal" labelWidth="100px">
      <a-form-item label="发起人" style="display: flex" :label-col="{style: `width:100px`}">
        <UserSelect
          ref="UserSelect"
          :echoData="userList"
          basicSetting
          @change="perModelConfirm"
        />
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import mixins from '@/components/CbPerDepSelection/index.js'
import UserSelect from '../Process/component/userSelect'
import { mapGetters } from 'vuex'
export default {
  name: "BasicSetting",
  mixins: [mixins],
  components: {
    UserSelect
  },
  props: {
    groupList: {
      type: Array,
      default: () => []
    },
    activeFlowGroupId: {
      type: String,
      default: ''
    },
    conf: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showSelectUser: false,
      PerDepModelVisible: false,
      userList: [],
      jsonData: {
        list: [
          {
            type: "input",
            label: "审批code",
            options: {
              type: "text",
              width: "100%",
              defaultValue: "",
              placeholder: "请输入审批code",
              clearable: false,
              maxLength: 50,
              addonBefore: "",
              addonAfter: "",
              hidden: false,
              disabled: false
            },
            model: "flowCode",
            key: "flowCode",
            help: "",
            rules: [
              {
                required: true,
                message: "请输入审批code"
              },
              {
                pattern: /^[a-zA-Z_\u4E00-\u9FA5][a-zA-Z0-9.-_\u4E00-\u9FA5]*$/,
                message: '必须以中文字母下划线开头，中间内容只能填写中文或字母或_或.'
              }
            ]
          },
          {
            type: "selectIcon",
            label: "图标",
            options: {
              multiple: false,
              disabled: false,
              width: "100%",
              clearable: true,
              placeholder: "请选择",
              showSearch: false
            },
            model: "icon",
            key: "selectIcon_1646901800785",
            rules: [
              {
                required: true,
                message: "请选择图标"
              }
            ]
          },
          {
            type: "input",
            label: "审批名称",
            options: {
              type: "text",
              width: "100%",
              defaultValue: "",
              placeholder: "请输入审批名称",
              clearable: false,
              maxLength: 10,
              addonBefore: "",
              addonAfter: "",
              hidden: false,
              disabled: false
            },
            model: "flowName",
            key: "input_1646901802025",
            help: "",
            rules: [
              {
                required: true,
                message: "请输入审批名称"
              }
            ]
          },
          {
            type: "input",
            label: "审批说明",
            options: {
              type: "text",
              width: "100%",
              defaultValue: "",
              placeholder: "请输入审批说明",
              clearable: false,
              maxLength: 100,
              addonBefore: "",
              addonAfter: "",
              hidden: false,
              disabled: false
            },
            model: "flowRemark",
            key: "input_1646901837214",
            help: "",
            rules: [
              {
                required: false,
                message: "必填项"
              }
            ]
          },
          {
            type: "select",
            label: "审批分组",
            options: {
              width: "100%",
              multiple: false,
              disabled: false,
              clearable: false,
              hidden: false,
              placeholder: "请选择",
              dynamicKey: "",
              dynamic: false,
              defaultValue: this.activeFlowGroupId,
              options: this.groupList.map(item => {
                return {
                  ...item,
                  label: item.groupName,
                  value: item.flowGroupId
                }
              }),
              showSearch: false
            },
            model: "flowGroupId",
            key: "select_1646901879170",
            help: "",
            rules: [
              {
                required: true,
                message: "必填项"
              }
            ]
          },
          {
            type: "select",
            label: "审批发起人",
            options: {
              width: "100%",
              multiple: false,
              disabled: false,
              clearable: false,
              hidden: false,
              placeholder: "请选择",
              dynamicKey: "",
              dynamic: false,
              options: [
                {
                  value: "assignperson",
                  label: "指定成员"
                },
                {
                  value: "all",
                  label: "全员"
                }
              ],
              showSearch: false
            },
            model: "initiatorScope",
            key: "select_1646901938529",
            help: "",
            rules: [
              {
                required: true,
                message: "请选择发起人"
              }
            ]
          }
        ],
        config: {
          layout: "horizontal",
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: "flex",
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters('designer', ['getPredefine'])
  },
  mounted() {
    this.$nextTick(() => {
      this.jsonData.list[0].options.hidden = !this.getPredefine
      || process.env.VUE_APP_ENV !== 'development'
      this.$refs.KFB.setData(this.conf)
      if (this.conf && this.conf.initiatorScope === 'assignperson') {
        this.showSelectUser = true
        this.userList = this.conf.initiatorList.map(item => {
          // 流程里面的字段和基础设置的不一样，这里做个判断
          return {
            ...item,
            id: item.initiatorId,
            title: item.initiatorName,
            identification: item.initiatorType === 'org' ? 'dep' : 'peo'
          }
        })
      }
    })
  },
  methods: {
    change(value, key) {
      // 发起人范围改变为指定成员
      if (key === 'initiatorScope' && value === 'assignperson') {
        this.showSelectUser = true
      } else if(key === 'initiatorScope' && value === 'all') {
        this.$refs.UserSelect && this.$refs.UserSelect.clear()
        this.$nextTick(() => {
          this.showSelectUser = false
          this.userList = []
        })
      }
    },
    getErrList() {
      return new Promise(async (resolve, reject) => {
        const errList = []
        if (this.showSelectUser && this.userList.length === 0) {
          errList.push({
            message: '请选审批发起人'
          })
        }
        this.$refs.KFB.getData()
        .then(res => {
          resolve(errList)
        })
        .catch(err => {
          Object.values(err).forEach(item => {
            item.errors.forEach(key => {
              errList.push(key)
            })
          })
          resolve(errList)
        })
      })
    },
    async getData() {
      return new Promise(async (resolve, reject) => {
        const res = await this.$refs.KFB.onlyGetData().catch(err => {
          resolve({})
        })
        // 发起人范围为指定成员的时候
        if (this.showSelectUser && res) {
          res['initiatorList'] = this.userList.map(item => {
            return {
              ...item,
              initiatorId: item.id,
              initiatorName: item.title,
              initiatorType: item.identification === 'dep' ? 'org' : 'employee'
            }
          })
        }
        // 判断是否为内置流程
        if (this.getPredefine && res) res['predefine'] = true
        resolve(res)
      })
    },
    perModelConfirm(userList) {
      this.userList = userList
    }
  }
};
</script>
<style lang="less" scoped>
.setting-container{
  width: 744px;
  height: 100%;
  margin: 0 auto;
  padding-top: 38px;
  border-left: 1px solid @sc-greyBg-20;
  border-right: 1px solid @sc-greyBg-20;
}
.form {
  width: 600px;
  margin: 0 auto;
}
/deep/.ant-form-item-control-wrapper{
  flex: 1;
  width: auto;
}
</style>
