<template>
  <div class="userSelect">
    <a-space class="userSpace">
      <a-button @click="PerDepModelVisible = true" type="link" prefix="add-one" class="el-but">
      </a-button>
      <template v-for="(userItem, userItemIndex) in userList">
        <div class="userListItem" :key="`userListItem${userItemIndex}`">
          <!-- 人员 -->
          <template v-if="userItem.identification === 'peo' || userItem.initiatorType === 'peo'">
            <CbAvater :avater-name="userItem.title || userItem.participantName" :on-job-id="userItem.initiatorId" size="30" />
            <!-- <img
              v-if="hasAvator"
              class="spa"
              :src="`${basePath}/hrsaas-base/staff/getAvatar?onJobId=${userItem.initiatorId}&width=${30}&height=${30}`"
              @error="errFunc"
            />
            <span v-else class="spa">{{ userItem.title.length > 2 ? userItem.title.slice(userItem.title.length - 2, userItem.title.length) :  userItem.title}}</span>
            {{ userItem.title || userItem.participantName }} -->
            <svg class="iconpark-icon closeIcon" @click="delUser(userItemIndex)"><use href="#close-small"></use></svg>
            <!-- <a-icon class="closeIcon" type="close" @click="delUser(userItemIndex)" /> -->
          </template>
          <!-- 组织 -->
          <template v-else>
            <img class="orgImg" :src="imgBase" />
            {{ userItem.title || userItem.participantName }}
            <svg class="iconpark-icon closeIcon" @click="delUser(userItemIndex)"><use href="#close-small"></use></svg>
            <!-- <a-icon class="closeIcon" type="close" @click="delUser(userItemIndex)" /> -->
          </template>
        </div>
      </template>
    </a-space>
    <PerDepModel
      ref="PerDepModel"
      v-model="PerDepModelVisible"
      :placeholder="'请输入部门、人员、工号'"
      :checkEcho="checkEcho"
      @perModelConfirm="perModelConfirm"
    />
    <!-- 存在选择的部门没有人员的情况下弹窗提示 -->
    <a-modal 
      v-model="confirmVisible" 
      title="提示" 
      ok-text="确认"
      :zIndex="9999"
      @ok="confirm"
    >
      <p>{{ confirmText }} 以上部门中不存在人员，是否确定选择</p>
    </a-modal>
  </div>
</template>

<script>
import mixins from '@/components/CbPerDepSelection/index.js'
export default {
  name: 'UserSelect',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  mixins: [mixins],
  props: {
    echoData: {
      type: Array,
      default: () => []
    },
    // 是否是再基础设置中使用的
    basicSetting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      PerDepModelVisible: false,
      confirmVisible: false,
      confirmText: '',
      hasAvator: true,
      checkEcho: [],
      userList: [],
      // basePath: process.env.VUE_APP_API_BASE_URL,
      // 再二次确认前缓存的一份儿人员信息数据
      copyUserList: [],
      // 组织的图片
      imgBase: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAqCAYAAAD4Uag9AAAABHNCSVQICAgIfAhkiAAAA/tJREFUaIHdmj1QG3cQxX/vxJdHzJg0uAsybWDGpVxFGddRzinThBSp7RKTwkpD6AJtKIwb0hnRhzHu7CpkYCZV4o/SRRx7IALju5fikDkSkHSIjzOv0q32Tu/p9r+7/70Th+DmncanNpWAuGSpdJjPWcGwhuL79enBtSznKX0QTjYqkheAkdSFnwptnQzNbBBcNS7uHT43vl3/oVjv8NwEX05t1WzdFdqK0bwUrOPg5akwzgLFw8TRdXAVMSx74cFM8Zu2p8H7O/XQ8CRw348W53KHWkGmaO1+C77RibgAQPKC0FZeRQEkvHpnQSuWJsKpxkQr/yC8sxUCIzGaz6uog+idxbyUXWvlFQTiGoAUrJ8Jr5OAtAKMhJON0lEuPTbJl5kSxW4VKHdFrhvYV/bSXgl4dphLD6gE2uj8qrvT4HHDC8HTLikeD+Iv4HeC6O+jXHqyXXH3K/C45O+Xpou17tidLoKM/p8Djx7kXBRkEaaoDB60tHB6dE4OnQtznCSLvu2OWprzRoZQ9BjwW7320ZELNk/oMHm8GwWuCGbS1nCyUZL2G+bzgPu3D/2zO82KNwBi60AYSr4HVLpm1wW008/NO/88t3WPge25psgOhXkMeF6fufSsaUmqviuGJ7iw3PLHA4o4Hj02+3bscFlyjbf9t8Opzc/q04Nr7YUpHsYexcwdMOPbAFLPT9CmazFA4fHxqbehCIt2NA7Rd0KrYe1VqX3yiKPrCbf9MAxrr4aQJ0AbudizAVJhXSrMgi7zdiBsL0yUjd/UZy6tvre9HQhBl41+OUWu2eEkKoRbC5MpgscESwftvoV5KXpWTpPn8aANzFBLYbHelQEM+2E42agA1/a2DnlECdoW6Pg6QHqAIsUTyYdCvsIQkkSHByWvtg5FtAl7yYJmitfXoJW8JI007HgcII6D1sJQkCzGnf6lcLJxV/JD0KbcO38GPDNDkNTKge02dcyFxxD/bFyVXAH9gYI5k9fZyH4/20Hn0bsoWEzGX2wlxTav8Cj4PmTo7vM+wUo6D7CCVci+g84tpCRxQLQGmWce+YWTMHzTfHhxce4Y+kTo1+bxxRCWKsxN04UQli7MTduFECY8DpDegQQAxlfPidMJISnMaUuAWRMUkzj98LBXv64IFtL2wEGcGBx/cfa0uoeIqvD/QVNQnx5cQyxDXG1W7w8GisqIMmYuPWiC5hrr25kAvZaiqWSUnX/Y0TiObhleeGCn9t/v3z9cD6c2r+FgWfAxaF0Ey6bw5EzZdgLFwxBXk6Wj11ZUOexViYOvQ9ReDWm7v4a4lXL5k9xsU5xeKo/cvxMeNXLXYcZwslEK5NCighk6FY7HhOTVGNfbvdDyL6TXmYt7pBqKAAAAAElFTkSuQmCC'
    }
  },
  watch: {
    async PerDepModelVisible() {
      if (this.PerDepModelVisible) {
        this.formDataEcho()
      }
    }
  },
  async mounted() {
    this.formDataEcho()
  },
  methods: {
    formDataEcho() {
      console.log('开始格式化数据')
      if(this.basicSetting) {
        this.checkEcho = JSON.parse(JSON.stringify(this.echoData))
        this.userList = JSON.parse(JSON.stringify(this.echoData)).map(item => {
          return {
            ...item,
            initiatorType: item.identification === 'dep' ? 'org' : 'employee'
          }
        })
      } else {
        this.userList = JSON.parse(JSON.stringify(this.echoData)).map(item => {
          return {
            ...item,
            title: item.participantName,
            id: item.participant,
            initiatorType: item.participantType === 'assignorg' ? 'org' : 'peo'
          }
        })
        // 格式化成人员选择框认识的数据
        this.checkEcho = this.echoData.map(item => {
          return {
            ...item,
            title: item.participantName,
            id: item.participant,
            identification: item.participantType === 'assignorg' ? 'dep' : 'peo'
          }
        })
      }
    },
    perModelConfirm(userList) {
      const noPersonOrgList = []
      this.copyUserList = JSON.parse(JSON.stringify(userList)).map(item => {
        if (item.identification === 'dep' && item.staffCount <= 0) {
          noPersonOrgList.push(item)
        }
        return {
          ...item,
          initiatorType: item.identification === 'dep' ? 'org' : 'employee'
        }
      })
      if (noPersonOrgList.length > 0) {
        this.confirmText = ''
        const orgNameList = []
        noPersonOrgList.forEach(item => {
          orgNameList.push(item.orgName)
        })
        this.confirmText = orgNameList.join('、')
        this.confirmVisible = true
      } else {
        this.PerDepModelVisible = false
        this.userList = this.copyUserList
        this.$emit('change', JSON.parse(JSON.stringify(userList)))
      }
    },
    // 所选部门存在无人员的情况的二次确认弹窗
    confirm() {
      this.PerDepModelVisible = false
      this.confirmVisible = false
      this.userList = this.copyUserList
      this.$emit('change', JSON.parse(JSON.stringify(this.userList)))
    },
    delUser(index) {
      this.userList.splice(index, 1)
      this.$emit('change', JSON.parse(JSON.stringify(this.userList)))
    },
    clear() {
      this.userList = []
      this.$refs.PerDepModel.clear()
    },
    errFunc() {
      this.hasAvator = false
    }
  }
}
</script>

<style lang="less" scoped>
.userSelect{
  display: flex;
  align-items: self-start;
  flex-wrap: wrap;
}
.el-but{
  border: 0 !important;
  padding: 0 !important;
  /deep/.iconpark-icon{
    width: 32px !important;
    height: 32px !important;
    margin: 0 !important;
  }
}
.select-icon{ 
  font-size: 32px;
  color: @sc-primary-100;
}
.userSpace{
  flex-wrap: wrap!important;
}
.userListItem{
  // width: 116px;
  height: 34px;
  border-radius: 153px;
  font-size: 14px!important;
  margin-left: 4px; 
  display: flex;
  align-items: center;
  background-color: rgba(143, 147, 161, 0.15);
  // margin-bottom: 10px;
  margin: 5px 0;
}
.spa{
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: @sc-primary-100;
  border-radius: 50%;
  margin-left: 0px;
  margin-right: 4px;
  line-height: 30px;
  color: rgba(255, 255, 255, 1);  
}
.orgImg{
  width: 30px;
  height: 30px;
  margin-right: 4px;
}
.closeIcon{
  width: 16px;
  height: 16px;
  margin: 0 14px;
  &:hover{
    cursor: pointer;
  }
}
</style>

