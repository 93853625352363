var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "condition-pane" }, [
    _vm.cacheData
      ? _c(
          "div",
          { staticClass: "content" },
          [
            _vm._l(
              _vm.cacheData.conditionGroups,
              function (conditionGroupsItem, index) {
                return [
                  index > 0
                    ? _c(
                        "div",
                        {
                          key: "conditionGroup" + index,
                          staticClass: "groupWord",
                        },
                        [_vm._v("或")]
                      )
                    : _vm._e(),
                  _c(
                    "a-card",
                    {
                      key: "conditionGroups" + index,
                      staticClass: "box-card",
                      attrs: { title: "条件组" },
                    },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            slot: "extra",
                            title: "确定要删除当前条件组吗?",
                            "ok-text": "是",
                            "cancel-text": "否",
                            placement: "bottomRight",
                          },
                          on: {
                            confirm: function ($event) {
                              return _vm.delItem(index)
                            },
                          },
                          slot: "extra",
                        },
                        [
                          _c(
                            "a-button",
                            { staticClass: "delete", attrs: { type: "link" } },
                            [
                              _c("svg", { staticClass: "iconpark-icon" }, [
                                _c("use", { attrs: { href: "#delete" } }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        conditionGroupsItem.details,
                        function (GroupsItem, index1) {
                          return [
                            _c(
                              "div",
                              {
                                key: "GroupsItem" + index1,
                                staticClass: "text item",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "group-item-start" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "group-item-title" },
                                      [
                                        _vm._v(
                                          _vm._s(index1 === 0 ? "当" : "且")
                                        ),
                                      ]
                                    ),
                                    index1 !== 0
                                      ? [
                                          _c(
                                            "a-popconfirm",
                                            {
                                              attrs: {
                                                title: "确定要删除当前条件吗?",
                                                placement: "bottomRight",
                                                "ok-text": "是",
                                                "cancel-text": "否",
                                              },
                                              on: {
                                                confirm: function ($event) {
                                                  return _vm.delConditionItem(
                                                    index,
                                                    index1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "group-item-del",
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "a-select",
                                  {
                                    staticClass: "w100",
                                    on: {
                                      change: function ($event) {
                                        return _vm.conditionTypeChange(
                                          GroupsItem
                                        )
                                      },
                                    },
                                    model: {
                                      value: GroupsItem.referName,
                                      callback: function ($$v) {
                                        _vm.$set(GroupsItem, "referName", $$v)
                                      },
                                      expression: "GroupsItem.referName",
                                    },
                                  },
                                  _vm._l(_vm.conditionSelect, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.referName,
                                        attrs: { value: item.referName },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.conditionName) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                                _vm.calcConditonTypeList
                                  .concat(_vm.matchConditonTypeList)
                                  .includes(GroupsItem.type)
                                  ? _c(
                                      "div",
                                      { staticClass: "group-item-condition" },
                                      [
                                        _vm.matchConditonTypeList.includes(
                                          GroupsItem.type
                                        )
                                          ? [
                                              _c(
                                                "a-select",
                                                {
                                                  staticClass: "w100",
                                                  on: {
                                                    change: function ($event) {
                                                      var i = arguments.length,
                                                        argsArray = Array(i)
                                                      while (i--)
                                                        argsArray[i] =
                                                          arguments[i]
                                                      return _vm.matchRuleChange.apply(
                                                        void 0,
                                                        argsArray.concat(
                                                          [GroupsItem],
                                                          ["matchRuleList"]
                                                        )
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: GroupsItem.matchRule,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        GroupsItem,
                                                        "matchRule",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GroupsItem.matchRule",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.matchRuleList,
                                                  function (item) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: item.value,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.label) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : _vm.calcConditonTypeList.includes(
                                              GroupsItem.type
                                            )
                                          ? [
                                              _c(
                                                "a-select",
                                                {
                                                  staticClass: "w100",
                                                  on: {
                                                    change: function ($event) {
                                                      var i = arguments.length,
                                                        argsArray = Array(i)
                                                      while (i--)
                                                        argsArray[i] =
                                                          arguments[i]
                                                      return _vm.conditiionRnageChange.apply(
                                                        void 0,
                                                        argsArray.concat(
                                                          [GroupsItem],
                                                          [
                                                            "conditionRangeOptions",
                                                          ]
                                                        )
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      GroupsItem.conditionRange,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        GroupsItem,
                                                        "conditionRange",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GroupsItem.conditionRange",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.conditionRangeOptions,
                                                  function (item, index3) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key:
                                                          "conditionRangeOptions" +
                                                          index3,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.label) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                GroupsItem.type
                                  ? _c(
                                      "div",
                                      { staticClass: "group-item-content" },
                                      [
                                        GroupsItem.type === "startPerson"
                                          ? [
                                              _c("PerTreeSelect", {
                                                attrs: {
                                                  perTreeValue:
                                                    GroupsItem.content,
                                                  modeType: "multiple",
                                                },
                                                on: {
                                                  getUserInfo: function (
                                                    $event
                                                  ) {
                                                    var i = arguments.length,
                                                      argsArray = Array(i)
                                                    while (i--)
                                                      argsArray[i] =
                                                        arguments[i]
                                                    return _vm.selectChange.apply(
                                                      void 0,
                                                      argsArray.concat(
                                                        [true],
                                                        [GroupsItem],
                                                        ["name"],
                                                        ["id"]
                                                      )
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        [
                                          "org",
                                          "WKTransferTransferDept",
                                        ].includes(GroupsItem.type)
                                          ? [
                                              _c("DepTreeSelect", {
                                                attrs: {
                                                  treeSelectValue:
                                                    GroupsItem.content,
                                                  multiple: "",
                                                },
                                                on: {
                                                  getValueSelect: function (
                                                    $event
                                                  ) {
                                                    var i = arguments.length,
                                                      argsArray = Array(i)
                                                    while (i--)
                                                      argsArray[i] =
                                                        arguments[i]
                                                    return _vm.selectChange.apply(
                                                      void 0,
                                                      argsArray.concat(
                                                        [true],
                                                        [GroupsItem],
                                                        ["label"],
                                                        ["value"],
                                                        ["object"]
                                                      )
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        [
                                          "post",
                                          "WKTransferTransferPost",
                                        ].includes(GroupsItem.type)
                                          ? [
                                              _c("Post", {
                                                attrs: { mode: "multiple" },
                                                on: {
                                                  getPostValue: function (
                                                    $event
                                                  ) {
                                                    var i = arguments.length,
                                                      argsArray = Array(i)
                                                    while (i--)
                                                      argsArray[i] =
                                                        arguments[i]
                                                    return _vm.selectChange.apply(
                                                      void 0,
                                                      argsArray.concat(
                                                        [true],
                                                        [GroupsItem]
                                                      )
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: GroupsItem.content,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      GroupsItem,
                                                      "content",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "GroupsItem.content",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        [
                                          "rank",
                                          "WKTransferTransferJob",
                                        ].includes(GroupsItem.type)
                                          ? [
                                              _c("Rank", {
                                                attrs: { mode: "multiple" },
                                                on: {
                                                  getRankValue: function (
                                                    $event
                                                  ) {
                                                    var i = arguments.length,
                                                      argsArray = Array(i)
                                                    while (i--)
                                                      argsArray[i] =
                                                        arguments[i]
                                                    return _vm.selectChange.apply(
                                                      void 0,
                                                      argsArray.concat(
                                                        [true],
                                                        [GroupsItem]
                                                      )
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: GroupsItem.content,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      GroupsItem,
                                                      "content",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "GroupsItem.content",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        [
                                          "duty",
                                          "work_address",
                                          "staff_status",
                                          "contract_type",
                                          "contract_company",
                                          "engage_mode",
                                        ].includes(GroupsItem.type)
                                          ? [
                                              _c("DictionariesInput", {
                                                attrs: {
                                                  dictionariesValue:
                                                    GroupsItem.content,
                                                  mode:
                                                    GroupsItem.parameter ===
                                                    "duty"
                                                      ? "default"
                                                      : "multiple",
                                                  parameter:
                                                    GroupsItem.parameter,
                                                },
                                                on: {
                                                  getData: function ($event) {
                                                    var i = arguments.length,
                                                      argsArray = Array(i)
                                                    while (i--)
                                                      argsArray[i] =
                                                        arguments[i]
                                                    return _vm.selectChange.apply(
                                                      void 0,
                                                      argsArray.concat(
                                                        [true],
                                                        [GroupsItem]
                                                      )
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        ["WKMultiSelect", "WKSelect"].includes(
                                          GroupsItem.type
                                        )
                                          ? [
                                              _c(
                                                "a-select",
                                                {
                                                  staticClass: "w100",
                                                  attrs: {
                                                    mode:
                                                      GroupsItem.type ===
                                                      "WKMultiSelect"
                                                        ? "multiple"
                                                        : "default",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var i = arguments.length,
                                                        argsArray = Array(i)
                                                      while (i--)
                                                        argsArray[i] =
                                                          arguments[i]
                                                      return _vm.conditionContentChange.apply(
                                                        void 0,
                                                        argsArray.concat([
                                                          GroupsItem,
                                                        ])
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: GroupsItem.content,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        GroupsItem,
                                                        "content",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GroupsItem.content",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.optionList[
                                                    GroupsItem.referName
                                                  ] || [],
                                                  function (item) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: item.referName,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.label) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm.calcConditonTypeList.includes(
                                          GroupsItem.type
                                        )
                                          ? [
                                              _c(
                                                "NumberInput",
                                                {
                                                  attrs: {
                                                    precision: 1,
                                                    decimalSeparator:
                                                      GroupsItem.type ===
                                                      "WKDateRange",
                                                  },
                                                  on: {
                                                    change: function (val) {
                                                      return (GroupsItem[
                                                        "contentName"
                                                      ] = val)
                                                    },
                                                  },
                                                  model: {
                                                    value: GroupsItem.content,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        GroupsItem,
                                                        "content",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GroupsItem.content",
                                                  },
                                                },
                                                [
                                                  GroupsItem.element
                                                    ? _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "addonAfter",
                                                          },
                                                          slot: "addonAfter",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              GroupsItem.element
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm.dataEchoConditonTypeList.includes(
                                          GroupsItem.type
                                        ) &&
                                        ![
                                          "WKTransferTransferDept",
                                          "WKTransferTransferPost",
                                          "WKTransferTransferJob",
                                        ].includes(GroupsItem.type)
                                          ? [
                                              _c("DictionariesInput", {
                                                attrs: {
                                                  dictionariesValue:
                                                    GroupsItem.content,
                                                  parameter:
                                                    GroupsItem.parameter,
                                                },
                                                on: {
                                                  getData: function ($event) {
                                                    var i = arguments.length,
                                                      argsArray = Array(i)
                                                    while (i--)
                                                      argsArray[i] =
                                                        arguments[i]
                                                    return _vm.selectChange.apply(
                                                      void 0,
                                                      argsArray.concat(
                                                        [GroupsItem],
                                                        ["string"]
                                                      )
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        }
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "addConditionBtn",
                          attrs: { type: "link", prefix: "add-one" },
                          on: {
                            click: function ($event) {
                              return _vm.addCondition(
                                conditionGroupsItem.details
                              )
                            },
                          },
                        },
                        [_vm._v("添加条件")]
                      ),
                    ],
                    2
                  ),
                ]
              }
            ),
            _c(
              "a-button",
              {
                staticClass: "addGroupBtn",
                attrs: { type: "link", prefix: "add-one" },
                on: {
                  click: function ($event) {
                    return _vm.addConditionGroup(_vm.cacheData.conditionGroups)
                  },
                },
              },
              [_vm._v("添加条件分组")]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }