var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userSelect" },
    [
      _c(
        "a-space",
        { staticClass: "userSpace" },
        [
          _c("a-button", {
            staticClass: "el-but",
            attrs: { type: "link", prefix: "add-one" },
            on: {
              click: function ($event) {
                _vm.PerDepModelVisible = true
              },
            },
          }),
          _vm._l(_vm.userList, function (userItem, userItemIndex) {
            return [
              _c(
                "div",
                {
                  key: "userListItem" + userItemIndex,
                  staticClass: "userListItem",
                },
                [
                  userItem.identification === "peo" ||
                  userItem.initiatorType === "peo"
                    ? [
                        _c("CbAvater", {
                          attrs: {
                            "avater-name":
                              userItem.title || userItem.participantName,
                            "on-job-id": userItem.initiatorId,
                            size: "30",
                          },
                        }),
                        _c(
                          "svg",
                          {
                            staticClass: "iconpark-icon closeIcon",
                            on: {
                              click: function ($event) {
                                return _vm.delUser(userItemIndex)
                              },
                            },
                          },
                          [_c("use", { attrs: { href: "#close-small" } })]
                        ),
                      ]
                    : [
                        _c("img", {
                          staticClass: "orgImg",
                          attrs: { src: _vm.imgBase },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(userItem.title || userItem.participantName) +
                            " "
                        ),
                        _c(
                          "svg",
                          {
                            staticClass: "iconpark-icon closeIcon",
                            on: {
                              click: function ($event) {
                                return _vm.delUser(userItemIndex)
                              },
                            },
                          },
                          [_c("use", { attrs: { href: "#close-small" } })]
                        ),
                      ],
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
      _c("PerDepModel", {
        ref: "PerDepModel",
        attrs: {
          placeholder: "请输入部门、人员、工号",
          checkEcho: _vm.checkEcho,
        },
        on: { perModelConfirm: _vm.perModelConfirm },
        model: {
          value: _vm.PerDepModelVisible,
          callback: function ($$v) {
            _vm.PerDepModelVisible = $$v
          },
          expression: "PerDepModelVisible",
        },
      }),
      _c(
        "a-modal",
        {
          attrs: { title: "提示", "ok-text": "确认", zIndex: 9999 },
          on: { ok: _vm.confirm },
          model: {
            value: _vm.confirmVisible,
            callback: function ($$v) {
              _vm.confirmVisible = $$v
            },
            expression: "confirmVisible",
          },
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(_vm.confirmText) + " 以上部门中不存在人员，是否确定选择"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }